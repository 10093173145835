import {
  HierarchicalMenuProps,
  ToggleRefinement,
  useCurrentRefinements,
  useHierarchicalMenu,
  useToggleRefinement,
} from "react-instantsearch";
import React from "react";
import { AuxRefinementMenu } from "./aloglia.refinement.menu";

export const AuxLocationRefinements = () => {
  const { items, refine } = useHierarchicalMenu({
    attributes: ["hierarchicalLocations.lvl0", "hierarchicalLocations.lvl1", "hierarchicalLocations.lvl2"],
  });
  return (
    <details className="dropdown">
      <summary>Where</summary>
      <AuxRefinementMenu items= {items} refine={refine} />
    </details>
  );
};
