/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from "typed-redux-saga";
import * as orderActions from "../../domain/orders/actions";
import { FetchOrderActionType, FetchOrdersActionType, OrderActionType } from "../../interfaces/actionTypes";
import Api from "../../libs/api";
import { showAxiosErrors, toast } from "../../libs/helpers";

export function* fetchOrders({ payload: { filters } }: FetchOrdersActionType) {
  try {
    const { data } = yield call(Api.fetchOrders, { filters });
    yield put({ type: orderActions.FETCH_ORDERS.Success, payload: data });
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: orderActions.FETCH_ORDERS.Failure,
      payload: errors,
    });
  }
}

export function* fetchOrder({ payload: { orderId } }: FetchOrderActionType) {
  try {
    const { data } = yield call(Api.fetchOrder, { orderId });

    yield put({
      type: orderActions.FETCH_ORDER.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);

    yield put({
      type: orderActions.FETCH_ORDER.Failure,
      payload: errors,
    });
  }
}

export function* createOrder({ payload: { auctionId, order }, navigate }: OrderActionType) {
  try {
    const { data } = yield call(Api.createOrder, { auctionId, order });
    yield put({
      type: orderActions.CREATE_ORDER.Success,
      payload: data,
    });
    toast("Order created successfully", "success");
    navigate(`/auction/${auctionId}`);
  } catch (error: any) {
    showAxiosErrors(error.response);
    yield put({ type: orderActions.CREATE_ORDER.Failure, payload: error });
  }
}

export function* updateOrder({ payload: { auctionId, order }, navigate }: OrderActionType) {
  try {
    const { data } = yield call(Api.updateOrder, { auctionId, order });
    yield put({
      type: orderActions.UPDATE_ORDER.Success,
      payload: data,
    });
    toast("Order updated successfully", "success");
    navigate(`/auction/${auctionId}`);
  } catch (error: any) {
    showAxiosErrors(error.response);
    yield put({ type: orderActions.CREATE_ORDER.Failure, payload: error });
  }
}
