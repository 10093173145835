import { useCurrentRefinements, useRefinementList } from "react-instantsearch";
import React from "react";
export const AuxDeliveryRefinement = () => {
  const { items, refine: deliveryRefine } = useRefinementList({
    attribute: "delivery",
    operator: "or",
  });
  const { items: refined, refine: removeRefinement } = useCurrentRefinements();
  return (
    <details className="dropdown">
      <summary>How {}</summary>
      <ul>
        {items.map((item) => (
          <li key={item.value}>
            <label>
              <input
                type="checkbox"
                name={item.label}
                value={item.value}
                checked={item.isRefined}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    deliveryRefine(e.currentTarget.value);
                  } else {
                    refined
                      .filter((refinement) => refinement.attribute === "delivery")
                      .map((refinement) => refinement.refinements)
                      .flat()
                      .filter((refinement) => refinement.value === item.value)
                      .map((refinement) => removeRefinement(refinement));
                  }
                }}
              />
              {item.label.substring(0, 1).toUpperCase() + item.label.substring(1)}
            </label>
          </li>
        ))}
      </ul>
    </details>
  );
};
