import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as auctionsActions from "../../domain/auctions/actions";

import { useParams } from "react-router-dom";
import { useFormik } from "formik";

const AuctionEdit: React.FC = () => {
  const { auctionId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (auctionId) {
      dispatch({ type: auctionsActions.fetchAuction.Request, payload: { auctionId } });
    } else {
      dispatch({ type: auctionsActions.clearAuction.Request });
    }
  }, [dispatch, auctionId]);
  /*
    useEffect(() => {
      setImage(auction.image);
    }, [auction]);
  
    useEffect(() => {
      setImage(formImage);
    }, [formImage]);
  
  const handleBeforeSubmit = (formValues: SingleAuctionValues): void => {
    if (!formValues.title || !formValues.endTime) {
      toast.error("fill all fields");
      return;
    }

    const auctionToSend: SingleAuctionValues = {
      title: formValues.title,
      buyoutPrice: formValues.buyoutPrice,
      reservePrice: formValues.reservePrice,
      endTime: parseISO(formValues.endTime).toISOString(),
      description: formValues.description,
      antiSnipe: ""
    };

    if (auctionId) {
      dispatch({
        type: auctionsActions.updateAuction.Request,
        payload: { auctionId, updatedAuction: auctionToSend },
        history,
      });
    } else {
      dispatch({
        type: auctionsActions.createAuction.Request,
        payload: { newAuction: auctionToSend },
        history,
      });
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formData: FormData = new FormData();
    if (e.target.files) {
      formData.append("file", e.target.files[0]);
    }
    dispatch<UploadCoverActionType>({ type: auctionsActions.uploadCover.request, payload: { formData } });
  };
  */
  return (
    <section className="container">
      <h6>{!!auctionId ? "Edit Auction" : "Create Auction"}</h6>
      <p>TODO EDIT AUCTION FORM</p>
    </section>
  );
};

export default AuctionEdit;
