import React from "react";
import { UseHitsProps } from "react-instantsearch";
import { useHits } from "react-instantsearch";
import AlgoliaRow from "./algolia.row";
const AlgoliaTable = (props: UseHitsProps) => {
  const { items } = useHits(props);
  return (
    <table className="overflow-auto">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col">Price</th>
          <th scope="col">Location</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <AlgoliaRow item={item} key={item.objectID} />
        ))}
      </tbody>
    </table>
  );
};

export default AlgoliaTable;
