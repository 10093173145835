import React from "react";

interface Props {}

const AuctionToolbar: React.FC<Props> = () => {
  const [value, setValue] = React.useState<number | null>(2);
  const [hover, setHover] = React.useState(-1);


  return <div role="group">
    <button onClick={() => void console.log("eye")} className="fa fa-eye"/>
  </div>;
  /*(
    <Toolbar variant="dense" color="inherit" disableGutters={true}>
      <ChevronRightIcon />
      <Breadcrumbs aria-label="breadcrumb" >
        <Link color="inherit" to="/">
          Some
        </Link>
        <Link color="inherit"  to="/">
          category
        </Link>
        <Link color="textPrimary" to="/" aria-current="page">
          level
        </Link>
      </Breadcrumbs>
      <div/>
      <IconButton
        aria-label="rating menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <StarIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        variant="menu"
        open={false}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem>
          <Rating
            name="rating"
            value={value}
            precision={0.5}
            onChange={(event, val) => {
              setValue(val);
            }}
            onChangeActive={(event, hover) => {
              setHover(hover);
            }}
          />
          {value !== null && <Box ml={2}>{ratingLabels[hover !== -1 ? hover : value]}</Box>}
        </MenuItem>
      </Menu>
      <IconButton aria-label="save for later" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
        <FavoriteIcon />
      </IconButton>
      <IconButton aria-label="seller" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
        <PersonIcon />
      </IconButton>
    </Toolbar>
  );*/
};

export default AuctionToolbar;
