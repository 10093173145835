/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from "typed-redux-saga";
import * as bidsActions from "../../domain/bids/actions";
import { BidsActionType } from "../../interfaces/actionTypes";
import Api from "../../libs/api";
import { showAxiosErrors, toast } from "../../libs/helpers";

export function* fetchBids({ payload: { auctionId } }: BidsActionType) {
  try {
    const { data } = yield call(Api.fetchBids, { auctionId });

    yield put({
      type: bidsActions.FETCH_BIDS.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);

    yield put({
      type: bidsActions.FETCH_BIDS.Failure,
      payload: errors,
    });
  }
}