import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: any;
}
interface ScaledMediaCardOptions {
  url?: string;
  scale?: {
    width?: number;
    height?: number;
  };
}
export const ScaledMedia: React.FC<ScaledMediaCardOptions> = ({
  url,
  scale = { width: 64, height: 64 },
}: ScaledMediaCardOptions) => {
  return (
    <img
      style={{
        width: "auto",
        height: `${scale.height}px`,
      }}
      src={`${url || "https://picsum.photos/id/665"}/${scale.width}/${scale.height}`}
    />
  );
};

const AlgoliaCard: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  return (
    <article>
      <header>
        <h5 onClick={() => navigate("/auction/" + item.objectID)}>{item.title}</h5>
      </header>
      <p>
        <ScaledMedia url={item.cover_url} scale={{ height: 128, width: 128 }} />
      </p>
    </article>
  );
};
export default AlgoliaCard;
