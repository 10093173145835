//import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StateInterface } from "../../domain";
import { searchTermChange } from "../../domain/auctions/actions";
import { useAuth } from "react-oidc-context";

const useDebounce = (handler: () => void, watchedValue: string[], delay: number) => {
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      handler();
    }, delay);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [handler, watchedValue, delay]);
};

const Navbar: React.FC = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    profile: { firstName },
  } = useSelector((state: StateInterface) => state.user);
  const {
    filter: { term, categories },
  } = useSelector((state: StateInterface) => state.auctions);
  const qs = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState(term || qs.get("q") || "");

  const handleSearchTerm = (term: string) => {
    const queryString = [];

    if (term && term.length > 2) {
      queryString.push(`q=${encodeURI(searchTerm)}`);
    }
    if (categories.length) {
      queryString.push(`categories=${categories.join(",")}`);
    }
    if (queryString.length) {
      navigate(`/auctions?${queryString.join("&")}`);
    } else if (queryString.length === 0) {
      navigate("/");
    }
    setSearchTerm(term);
  };
  useDebounce(
    () => {
      if (term !== searchTerm && searchTerm.length > 2) {
        dispatch({
          type: searchTermChange.Event,
          payload: {
            term: searchTerm,
          },
        });
      }
    },
    [searchTerm],
    500,
  );
  const auth = useAuth();

  const UserMenu = ({ firstName }: { firstName: string }) => (
    <details className="dropdown">
      <summary>
        <i className="fa fa-account" /> Menu
      </summary>
      <ul>
        <li>Notifications</li>
        <li>
          <a href="#">My Auctions</a>
        </li>
        <li>
          <a href="#">Current bids</a>
        </li>
        <li>
          <a href="#" onClick={() => navigate("/profile")}></a>
        </li>
        <li>
          <a href="#" onClick={() => void auth.signoutPopup()}>
            Logout
          </a>
        </li>
      </ul>
    </details>
  );
  return (
    <nav>
      <ul>
        <li>
          <strong>Auctioneer</strong>
        </li>
      </ul>
      <ul>
        <li>
          <a onClick={(e) => navigate("/")}>
            <i className="fa fa-list" /> Items
          </a>
        </li>
        {auth.isAuthenticated ? (
          <>
            <li>
              <a onClick={(e) => navigate("/auction/create")}>
                <i className="fa fa-add" /> Create
              </a>
            </li>
            <li>
              <UserMenu firstName={firstName} />
            </li>
          </>
        ) : (
          <li>
            <div role="group">
              <button onClick={() => void auth.signinRedirect()}>Login</button>
              <button onClick={() => void auth.signinRedirect()}>Register</button>
            </div>
          </li>
        )}
      </ul>
      {/*false ? (
        <Toolbar>
          <SearchBar value={searchTerm} onChange={handleSearchTerm} className={classes.grow} />
        </Toolbar>
      ) : null*/}
    </nav>
  );
};

export default Navbar;
