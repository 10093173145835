/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Auctioneer API
 * Auctioneer API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateMediaDto,
  GetSignedUrlDto,
  Media,
  MediaDto,
  MulterUploadBody,
  ReadStream,
  SignedUrlDto,
  UpdateMediaDto,
} from "./model";
import { customAxiosInstance } from "../custom-axios";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createMedia = (createMediaDto: CreateMediaDto) => {
  return customAxiosInstance<Media>({
    url: `/v1/media`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createMediaDto,
  });
};

export const getCreateMediaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError, { data: CreateMediaDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError, { data: CreateMediaDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMedia>>, { data: CreateMediaDto }> = (props) => {
    const { data } = props ?? {};

    return createMedia(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMediaMutationResult = NonNullable<Awaited<ReturnType<typeof createMedia>>>;
export type CreateMediaMutationBody = CreateMediaDto;
export type CreateMediaMutationError = unknown;

export const useCreateMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError, { data: CreateMediaDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof createMedia>>, TError, { data: CreateMediaDto }, TContext> => {
  const mutationOptions = getCreateMediaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const mediaFindAll = (signal?: AbortSignal) => {
  return customAxiosInstance<Media[]>({ url: `/v1/media`, method: "GET", signal });
};

export const getMediaFindAllQueryKey = () => {
  return [`/v1/media`] as const;
};

export const getMediaFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof mediaFindAll>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof mediaFindAll>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMediaFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof mediaFindAll>>> = ({ signal }) => mediaFindAll(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof mediaFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MediaFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof mediaFindAll>>>;
export type MediaFindAllQueryError = unknown;

export const useMediaFindAll = <TData = Awaited<ReturnType<typeof mediaFindAll>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof mediaFindAll>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMediaFindAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMedia = (id: string, signal?: AbortSignal) => {
  return customAxiosInstance<Media>({ url: `/v1/media/${id}`, method: "GET", signal });
};

export const getGetMediaQueryKey = (id: string) => {
  return [`/v1/media/${id}`] as const;
};

export const getGetMediaQueryOptions = <TData = Awaited<ReturnType<typeof getMedia>>, TError = unknown>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMedia>>, TError, TData>> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedia>>> = ({ signal }) => getMedia(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMedia>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaQueryResult = NonNullable<Awaited<ReturnType<typeof getMedia>>>;
export type GetMediaQueryError = unknown;

export const useGetMedia = <TData = Awaited<ReturnType<typeof getMedia>>, TError = unknown>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMedia>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateMedia = (id: string, updateMediaDto: UpdateMediaDto) => {
  return customAxiosInstance<string>({
    url: `/v1/media/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateMediaDto,
  });
};

export const getUpdateMediaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMedia>>,
    TError,
    { id: string; data: UpdateMediaDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMedia>>,
  TError,
  { id: string; data: UpdateMediaDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMedia>>, { id: string; data: UpdateMediaDto }> = (
    props,
  ) => {
    const { id, data } = props ?? {};

    return updateMedia(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMediaMutationResult = NonNullable<Awaited<ReturnType<typeof updateMedia>>>;
export type UpdateMediaMutationBody = UpdateMediaDto;
export type UpdateMediaMutationError = unknown;

export const useUpdateMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMedia>>,
    TError,
    { id: string; data: UpdateMediaDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateMedia>>,
  TError,
  { id: string; data: UpdateMediaDto },
  TContext
> => {
  const mutationOptions = getUpdateMediaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteMedia = (id: string) => {
  return customAxiosInstance<string>({ url: `/v1/media/${id}`, method: "DELETE" });
};

export const getDeleteMediaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteMedia>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteMedia>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMedia>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return deleteMedia(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMediaMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMedia>>>;

export type DeleteMediaMutationError = unknown;

export const useDeleteMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteMedia>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteMedia>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteMediaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const data = (signal?: AbortSignal) => {
  return customAxiosInstance<ReadStream>({ url: `/v1/media/data/*`, method: "GET", signal });
};

export const getDataQueryKey = () => {
  return [`/v1/media/data/*`] as const;
};

export const getDataQueryOptions = <TData = Awaited<ReturnType<typeof data>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof data>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof data>>> = ({ signal }) => data(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof data>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type DataQueryResult = NonNullable<Awaited<ReturnType<typeof data>>>;
export type DataQueryError = unknown;

export const useData = <TData = Awaited<ReturnType<typeof data>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof data>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const multerUpload = (multerUploadBody: MulterUploadBody) => {
  const formData = new FormData();
  if (multerUploadBody.file !== undefined) {
    formData.append("file", multerUploadBody.file);
  }

  return customAxiosInstance<MediaDto[] | MediaDto>({
    url: `/v1/media/multer-upload`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

export const getMulterUploadMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof multerUpload>>, TError, { data: MulterUploadBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof multerUpload>>, TError, { data: MulterUploadBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof multerUpload>>, { data: MulterUploadBody }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return multerUpload(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MulterUploadMutationResult = NonNullable<Awaited<ReturnType<typeof multerUpload>>>;
export type MulterUploadMutationBody = MulterUploadBody;
export type MulterUploadMutationError = unknown;

export const useMulterUpload = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof multerUpload>>, TError, { data: MulterUploadBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof multerUpload>>, TError, { data: MulterUploadBody }, TContext> => {
  const mutationOptions = getMulterUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
export const signedUrl = (getSignedUrlDto: GetSignedUrlDto) => {
  return customAxiosInstance<SignedUrlDto | void>({
    url: `/v1/media/signed-url`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: getSignedUrlDto,
  });
};

export const getSignedUrlMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof signedUrl>>, TError, { data: GetSignedUrlDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof signedUrl>>, TError, { data: GetSignedUrlDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof signedUrl>>, { data: GetSignedUrlDto }> = (props) => {
    const { data } = props ?? {};

    return signedUrl(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignedUrlMutationResult = NonNullable<Awaited<ReturnType<typeof signedUrl>>>;
export type SignedUrlMutationBody = GetSignedUrlDto;
export type SignedUrlMutationError = unknown;

export const useSignedUrl = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof signedUrl>>, TError, { data: GetSignedUrlDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof signedUrl>>, TError, { data: GetSignedUrlDto }, TContext> => {
  const mutationOptions = getSignedUrlMutationOptions(options);

  return useMutation(mutationOptions);
};
