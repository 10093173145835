import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import type { Hit, BaseHit } from "instantsearch.js";
interface AlgoliaRowProps {
  item: BaseHit;
}
interface ScaledMediaCardOptions {
  itemId: string;
  navigate?: NavigateFunction;
  url?: string;
  scale?: {
    width?: number;
    height?: number;
  };
}
const ScaledAuctionMediaCard: React.FC<ScaledMediaCardOptions> = ({
  itemId,
  navigate,
  url,
  scale = { width: 64, height: 64 }
}: ScaledMediaCardOptions) => {
  //TODO: Better fallback image
  return (
    <img

      src={`${url || "https://picsum.photos/id/665"}/${scale.width}/${scale.height}`}
      style={{ backgroundImage: "", cursor: "zoom-in" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDoubleClick={() => navigate && navigate("/auction/" + itemId)}
    />
  );
};
type AuxHit = {
  cover_url: string;
  title: string;
  askPrice: number;
  locations?: string[];
  seller: string;
  objectID: string;
};
const AlgoliaRow: React.FC<AlgoliaRowProps> = ({ item }) => {
  const { cover_url, title, ask_price, buyout_price, locations, seller, objectID } = item;
  const navigate = useNavigate();
  const highestBid = -1;
  return (
    <tr onClick={() => navigate("/auction/" + objectID)} style={{ cursor: "pointer" }}>
      <th scope="row">
        <ScaledAuctionMediaCard
          url={cover_url}
          scale={{ height: 96, width: 96 }}
          navigate={navigate}
          itemId={objectID}
        />
      </th>
      <td>{title}</td>
      <td>
        <ul>
          <li>Highest bid: {highestBid > -1? `{ask_price}`: <i className="fa fa-spin"/>}</li>
          <li>Ask: {ask_price}</li>
          {buyout_price ? <li>Buyout: {buyout_price}</li> : null}
        </ul>
      </td>
      <td>{locations?.length ? locations.reverse().join(", ") : ""}</td>
    </tr>
  );
};
export default AlgoliaRow;
