"use client";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  FC,
  MouseEvent,
  KeyboardEvent,
} from "react";

// Define the shape of the modal context
type ModalContextType = {
  modalIsOpen: boolean;
  handleOpen: (e?: globalThis.MouseEvent | MouseEvent | globalThis.KeyboardEvent | KeyboardEvent) => void;
  handleClose: (e?: globalThis.MouseEvent| MouseEvent | globalThis.KeyboardEvent | KeyboardEvent) => void;
  [key: string]: any; // To handle additional props
};

// Create the context with an undefined default value
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Custom hook to use the modal context
const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

// Define the props for the ModalProvider component
interface ModalProviderProps {
  children: ReactNode;
  [key: string]: any; // To handle additional props
}

// ModalProvider component
const ModalProvider: FC<ModalProviderProps> = ({ children, ...props }) => {
  const isSSR = typeof window === "undefined";
  const htmlTag = !isSSR ? document.querySelector("html") : null;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modalAnimationDuration = 400;

  // Handle open
  const handleOpen = (event: globalThis.MouseEvent | MouseEvent | globalThis.KeyboardEvent | KeyboardEvent | undefined) => {
    !!event && event.preventDefault();
    if (htmlTag) {
      setModalIsOpen(true);
      htmlTag.classList.add("modal-is-open", "modal-is-opening");
      setTimeout(() => {
        htmlTag.classList.remove("modal-is-opening");
      }, modalAnimationDuration);
    }
  };

  // Handle close
  const handleClose = (
    event: globalThis.MouseEvent | MouseEvent | globalThis.KeyboardEvent | KeyboardEvent | undefined,
  ) => {
    !!event && event.preventDefault();
    if (htmlTag) {
      htmlTag.classList.add("modal-is-closing");
      setTimeout(() => {
        setModalIsOpen(false);
        htmlTag.classList.remove("modal-is-open", "modal-is-closing");
      }, modalAnimationDuration);
    }
  };

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event: globalThis.KeyboardEvent) => {
      if (!modalIsOpen) return;
      if (event.key === "Escape") {
        handleClose(event);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [modalIsOpen]);

  // Set scrollbar width on mount
  useEffect(() => {
    const scrollBarWidth = 0;
    htmlTag?.style.setProperty("--pico-scrollbar-width", `${scrollBarWidth}px`);
    return () => {
      htmlTag?.style.removeProperty("--pico-scrollbar-width");
    };
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalIsOpen,
        handleOpen,
        handleClose,
        ...props,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, useModal };
