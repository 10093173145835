import { getAction } from "../../libs/actions";

export const FETCH_PROFILE = getAction("user/FETCH_PROFILE");
export const CREATE_USER = getAction("user/CREATE_USER");
export const LOGIN = getAction("user/LOGIN");
export const VERIFY_EMAIL = getAction("user/VERIFY_EMAIL");
export const LOGOUT = getAction("user/LOGOUT");
export const FORGOT_PASSWORD = getAction("user/FORGOT_PASSWORD");
export const RESET_PASSWORD = getAction("user/RESET_PASSWORD");

export const NOT_AUTHORIZED = getAction("user/NOT_AUTHORIZED");

export const SET_USER_FROM_STORAGE = getAction("user/SET_USER_FROM_STORAGE");
