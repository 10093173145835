import React from "react";
import { Route, Routes } from "react-router-dom";
import AuctionsDetails from "./pages/auction/auction.details";
import Algolia from "./pages/auctions/auctions";
import BidCreate from "./pages/bids/bidCreate";
import AuctionEdit from "./pages/auction/auction.edit";
import AuctionCreate from "./pages/auction/auction.create";

import { App } from "./App";
const Switcher: React.FC = () => (
  <Routes>
    <Route element={<App />}>
      {/* Auction */}
      <Route path="/auction">
        <Route path="/auction/create" element={<AuctionCreate />} />
        <Route path="/auction/:auctionId/edit" element={<AuctionEdit />} />
        <Route path="/auction/:auctionId/bid" element={<BidCreate />} />
        <Route path="/auction/:auctionId" element={<AuctionsDetails />} />
      </Route>
      {/* Auctions 
      <Route path="/auctions">
        <Route path="/auctions/mine">
          <Auctions />
        </Route>
        <Route path="/bids">
          <Auctions />
        </Route>
        <Route path="/:auctionId/order">
          <OrderEdit />
        </Route>
      </Route>
      */}
      {/* Search */}
      <Route path="/search" element={<Algolia />} />
      {/* Account 
      <Route path="/auth">
        <Route path="/auth/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/auth/reset_email">
          {" "}
          <ResetPassword />
        </Route>
        <Route path="/auth/verify/email">
          <VerifyEmail />
        </Route>
        <Route path="/auth/login">
          <Login />
        </Route>
        <Route path="/auth/signup">
          <SignUp />
        </Route>
        <Route path="/auth/signup/success">
          <SignUpSuccess />
        </Route>
      </Route>
      <Route path="/orders">
        <Route path="/:filters">
          <Orders />
        </Route>
      </Route>
*/}
      {/*window.location.hostname === "localhost" ? (
      <Route  path="/" render={() => (window.location.hostname = "www-aux.local.niklasblomberg.se")} />
    ) : (
      <Route  path="/" component={Algolia} />
    )*/}
      <Route path="*" element={<Algolia />} />
    </Route>
  </Routes>
);
export default Switcher;
