// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setStorageItem(key: string, value: any): void {
  return window.localStorage.setItem(key, value);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LocalStoredObject = {
  [string: string]: any;
};
export function getStorageItems(keys: string[]): LocalStoredObject {
  const obj: LocalStoredObject = {};
  keys.forEach((key) => {
    const value = window.localStorage.getItem(key);
    if (value) {
      obj[key] = value;
    }
  });
  return obj;
}
export function getStorageItem(key: string, defaultValue: any = ""): string {
  return window.localStorage.getItem(key) ?? defaultValue;
}
export function clearStorage(): void {
  window.localStorage.clear();
}
