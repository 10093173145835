import { Reducer } from "redux";
import { ActionType } from "../../interfaces/actionTypes";
import * as userActions from "./actions";
interface AddressInterface {
  addressLine1: string,
  line2: string,
}
interface ProfileInterface {
  email: string;
  firstName: string;
  lastName: string;
  deliveryAddress?: AddressInterface;
}
export interface UserStateInterface {
  id: number;
  profile: ProfileInterface
  token: string
  isLoading: boolean
  status: string
}

export const userInitialState: UserStateInterface = {
  id: 0,
  profile: {
    email: "",
    firstName: "",
    lastName: ""
  },
  token: "",
  isLoading: false,
  status: "",
}

export const userReducer: Reducer = (state: UserStateInterface = userInitialState, { type, payload }: ActionType): UserStateInterface => {
  switch (type) {
    case userActions.FETCH_PROFILE.Request:
      return { ...state };
    case userActions.LOGIN.Request:
      return { ...userInitialState, isLoading: true };
    case userActions.LOGIN.Success:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };
    case userActions.LOGIN.Failure:
      return { ...userInitialState, isLoading: false };

    case userActions.VERIFY_EMAIL.Success:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };

    //
    case userActions.VERIFY_EMAIL.Failure:
      return { ...state, isLoading: false };

    // direct from component without saga
    case userActions.SET_USER_FROM_STORAGE.Request:
      return { ...userInitialState, ...payload };

    // logout
    case userActions.LOGOUT.Success:
      return {
        ...userInitialState,
      };

    // create user
    case userActions.CREATE_USER.Request:
      return { ...userInitialState, isLoading: true };

    case userActions.CREATE_USER.Success:
      return {
        ...userInitialState,
        id: payload.resource.id,
        ...payload,
        isLoading: false,
      };

    case userActions.CREATE_USER.Failure:
      return { ...userInitialState, isLoading: false };

    default:
      return { ...state };
  }
};
