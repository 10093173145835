import { getAction, getEvent } from "../../libs/actions";
// Actions
export const fetchAuctions = getAction("auctions/FETCH_AUCTIONS");
export const fetchMyAuctions = getAction("auctions/FETCH_MY_AUCTIONS");
export const fetchMyBids = getAction("auctions/FETCH_MY_BIDS");
export const searchAction = getAction("auctions/SEARCH_AUCTIONS");
export const fetchAuction = getAction("auctions/FETCH_AUCTION");
export const clearAuction = getAction("auctions/CLEAR_AUCTION");
export const createAuction = getAction("auctions/CREATE_AUCTION");
export const updateAuction = getAction("auctions/UPDATE_AUCTION");
export const deleteAuction = getAction("auctions/DELETE_AUCTION");
export const setAuction = getAction("auctions/SET_AUCTION");

export const uploadCover = getAction("auctions/UPLOAD_COVER");

export const executeOrder = getAction("auctions/EXECUTE_COVER");
export const receiveOrder = getAction("auctions/RECEIVE_COVER");

// Events
export const changePagination = getEvent("auctions/CHANGE_PAGINATION");
export const changeDisplay = getEvent("auctions/CHANGE_DISPLAY");
export const changedCategoryFilter = getEvent("auctions/CHANGED_CATEGORY_FILTER");
export const searchTermChange = getEvent("auctions/SEARCH_TERM_CANGE");
export default {};
