import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { AppContainer } from "App";

const baseURL = `${process.env.REACT_APP_AUCTIONEER_API_URL}`;
console.log("baseURL: " + baseURL);
axios.defaults.baseURL = baseURL;

const rootElement = document.getElementById("auctioneer-app");

declare global {
  interface Window {
    auctioneerConfig?: { siteKey: string };
  }
}

let root;
if (rootElement?.hasChildNodes()) {
  root = hydrateRoot(rootElement!, <AppContainer />);
} else {
  root = createRoot(rootElement!);
  root.render(<AppContainer />);
}

// Hot Module Replacement
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare let module: { hot: any };

if (module.hot && process.env.NODE_ENV !== "production") {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
