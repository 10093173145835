import { fork } from "typed-redux-saga";

import watchComponents from "./../sagas/components";

export default function* rootSaga() {
  yield* [
    // components watchers
    fork(watchComponents),
  ];
}
