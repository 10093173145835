import algoliasearch, { SearchClient } from "algoliasearch";
import React, { useEffect, useState } from "react";
import AlgoliaSearchBar from "../../components/algolia/algolia.search.bar";
import AlgoliaItems from "../../components/algolia/algolia.cards";
import { InstantSearch } from "react-instantsearch";
import AlgoliaBottomBar from "../../components/algolia/algolia.bottombar";
import { useAuxStore } from "../../stores/aux.store";
import AlgoliaTable from "../../components/algolia/algolia.table";
import { ModalProvider } from "../../contexts/ModelContext";

const Algolia: React.FunctionComponent = () => {
  const { site, setMode, mode } = useAuxStore();
  const [searchClient, setSearchClient] = useState<SearchClient | undefined>();

  useEffect(() => {
    if (!!site?.searchKey) setSearchClient(algoliasearch("H605M8SD36", site.searchKey));
  }, [site]);

  return (
    <ModalProvider>
      {!searchClient ? (
        <div>Search not ready</div>
      ) : (
        <InstantSearch searchClient={searchClient} indexName="aux-staging">
          <AlgoliaSearchBar />
          <section className="container">{mode === "card" ? <AlgoliaItems /> : <AlgoliaTable />}</section>
          <div className="container">
            <AlgoliaBottomBar />
          </div>
        </InstantSearch>
      )}
    </ModalProvider>
  );
};

export default Algolia;
