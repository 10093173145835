import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { StateInterface } from "../../domain";
import * as auctionsActions from "../../domain/auctions/actions";
import { getWinnersBid } from "../../libs/helpers";
import { Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import { CreateBid, getPlaceBidMutationOptions } from "../../gen/rq";

const validationSchema = Yup.object({
  bid: Yup.number().min(0).required("Bid is required"),
});

const BidCreate: React.FC = () => {
  const { auctionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auction = useSelector((state: StateInterface) => state.auctions.resource);
  useEffect(() => {
    dispatch({ type: auctionsActions.fetchAuction.Request, payload: { auctionId } });
  }, [dispatch, auctionId]);

  const { mutationFn: placeBid } = getPlaceBidMutationOptions();

  const formik = useFormik<CreateBid>({
    initialValues: {
      bid: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      if (!auctionId) {
        toast.error("Missing auction with id: " + auctionId);
        return;
      }
      if (placeBid) await placeBid({ auctionId: auction.id, data: values });
    },
  });

  const winnersBid = getWinnersBid(auction.bids);
  return (
    <section className="bidCreate">
      <div className="auctionInfo">
        {!!auction && (
          <h2>
            <Link to={{ pathname: `/auction/${auction.id}` }}>Auction '{auction.title}'</Link>
          </h2>
        )}
        {!!auction && (
          <>
            <p>Max current price: ${!!winnersBid ? winnersBid.proposedPrice : auction.currentPrice}</p>
            <p>Estimated price: ${auction.estimatedPrice}</p>
          </>
        )}
      </div>
      <div className="title">Create Bid</div>

      <Form onSubmit={formik.handleSubmit}>
        <Field value={formik.values.bid} name="bid" type="text" component="input" placeholder="proposed price" />
        <div className="submitBtn">
          <button type="submit">Submit</button>
        </div>
      </Form>
    </section>
  );
};

export default BidCreate;
