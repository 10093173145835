
import { ScaledMedia } from "../algolia/algolia.card";
import { MediaDto, useMulterUpload } from "../../gen/rq";
import React, { useCallback, useState } from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
export const AuxUploadForm = () => {
  // const { mutate: mutateSignedUrl } = useSignedUrl();
  const { mutate: multerUpload } = useMulterUpload();
  const [medias, setMedias] = useState<MediaDto[]>([]);
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    acceptedFiles.forEach(async (file) => {
      // const response =  mutateSignedUrl({ data: { filename: file.name } })
      multerUpload({ data: { file } });
      console.log(acceptedFiles);
    });
  }, []);
  return (
    <section>
      <label>Media</label>
      <div className="grid">
        <Dropzone onDrop={onDrop} multiple>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  justifyContent: "center" /* Aligns horizontally */,
                  alignItems: "center" /* Aligns vertically */,
                  height: "128px",
                  width: "128px",
                  borderColor: "#CCC",
                  borderStyle: "dotted",
                }}
              >
                <input {...getInputProps()} />

                <i className="fa fa-plus" />
              </div>
            </section>
          )}
        </Dropzone>
        {(medias ?? Array.from({ length: Math.random() * 10 + 1 })).map((media, index) => (
          <div key={`media-${index}`}>
            <ScaledMedia />
          </div>
        ))}
      </div>
    </section>
  );
};
