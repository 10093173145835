import React, { useRef } from "react";
import {
  Configure,
  CurrentRefinements,
  useClearRefinements,
  useCurrentRefinements,
  useHierarchicalMenu,
  useInstantSearch,
  useRefinementList,
} from "react-instantsearch";
import { useConnector, AdditionalWidgetProperties } from "react-instantsearch";
import connectSearchBox, {
  SearchBoxConnectorParams,
  SearchBoxWidgetDescription,
} from "instantsearch.js/es/connectors/search-box/connectSearchBox";
import { AuxCurrentRefinements } from "./filters/aloglia.current.refinement";
import { AuxDeliveryRefinement } from "./filters/aloglia.delivery.refinement";
import { AuxLocationRefinements } from "./filters/aloglia.location.refinement";
import { AuxCategoryRefinements } from "./filters/aloglia.category.refinement";
export type UseSearchBoxProps = SearchBoxConnectorParams;
const useSearchBox = (props: UseSearchBoxProps, additionalWidgetProperties: AdditionalWidgetProperties) =>
  useConnector<SearchBoxConnectorParams, SearchBoxWidgetDescription>(
    connectSearchBox,
    props,
    additionalWidgetProperties,
  );

const SearchField = (props: UseSearchBoxProps) => {
  const { refine, query } = useSearchBox(props, {
    $$widgetType: "aux.searchBox",
  });
  const searchInputRef = useRef(null);
  return (
    <div role="group">
      <input
        ref={searchInputRef}
        value={query}
        type="input"
        name="search"
        placeholder="What are you looking for..."
        aria-label="Search"
        onChange={(e) => refine(e.currentTarget.value)}
      />
      {query.length > 0 && (
        <button type="reset" onClick={() => refine("")}>
          <i className="fa fa-remove"></i>
        </button>
      )}
      <button onClick={() => refine(query)}>Search</button>
    </div>
  );
};

const AlgoliaSearchBar = () => {
  const { items } = useCurrentRefinements();
  return (
    <section>
      <Configure analytics={false} />
      <div className="grid">
        <div className="grid">
          <AuxLocationRefinements />
          <AuxCategoryRefinements />
          <AuxDeliveryRefinement />
        </div>
        <SearchField />
      </div>
      {items.length ? <AuxCurrentRefinements /> : null}
    </section>
  );
};

export default AlgoliaSearchBar;
