import { useCurrentRefinements, useHierarchicalMenu } from "react-instantsearch";

import React from "react";
import { AuxRefinementMenu } from "./aloglia.refinement.menu";
export const AuxCategoryRefinements = () => {
  const { items, refine } = useHierarchicalMenu({
    attributes: ["hierarchicalCategories.lvl0", "hierarchicalCategories.lvl1", "hierarchicalCategories.lvl2"],
  });
  return (
    <details className="dropdown">
      <summary>What</summary>
      <AuxRefinementMenu items={items} refine={refine} sub={false}/>
    </details>
  );
};
