import { Reducer } from "redux";
import { CategoryDto } from "../../gen/axios-api";
import { ActionType } from "./../../interfaces/actionTypes";
import { Meta } from "./../index";
import * as siteActions from "./actions";

export interface AuctionFeeInterface {
  amount: number;
  currancy: string;
}

export interface SiteStateInterface {
  id: number;
  categoryTree: CategoryDto;
  auctionFee: AuctionFeeInterface;
  searchKey?: string;
  meta: Meta;
  isLoading: boolean;
}

export const siteInitialState: SiteStateInterface = {
  id: 0,
  categoryTree: { id: 0, name: "", children: [] } as CategoryDto,
  auctionFee: { amount: 0, currancy: "" } as AuctionFeeInterface,
  meta: {} as Meta,
  isLoading: false,
};

export const siteReducer: Reducer = (
  siteState: SiteStateInterface = siteInitialState,
  { type, payload }: ActionType
): SiteStateInterface => {
  switch (type) {
    case siteActions.FETCH_SITE.Request: {
      return { ...siteInitialState, isLoading: true };
    }
    case siteActions.FETCH_SITE.Success:
      return { ...siteState, ...payload, isLoading: false };

    case siteActions.FETCH_SITE.Failure:
      return { ...siteInitialState, isLoading: false };

    default:
      return { ...siteState };
  }
};
