/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, debounce, put } from "typed-redux-saga";
import * as auctionsActions from "../../domain/auctions/actions";
import {
  CreateAuctionActionType,
  DeleteAuctionActionType,
  ExecuteAuctionActionType,
  FetchAuctionActionType,
  FetchAuctionsActionPayload,
  FetchAuctionsActionType,
  ReceiveAuctionActionType,
  SetAuctionToAuctionActionType,
  UpdateAuctionActionType,
  UploadCoverActionType,
} from "../../interfaces/actionTypes";
import Api, { auctionsApi, auctionApi } from "../../libs/api";
import { showAxiosErrors, toast } from "../../libs/helpers";
import { SearchAuctionsActionType } from "./../../interfaces/actionTypes";

export function* fetchAuctions({ payload }: FetchAuctionsActionType) {
  const {
    filter: { categories },
    page,
    pageSize,
  } = payload;
  try {
    const { data } = yield call((payload: FetchAuctionsActionPayload) => {
      const csvCategories = categories?.length ? categories : [];
      return auctionsApi.allAuctions(csvCategories.join(","), pageSize, page);
    }, payload);

    yield put({
      type: auctionsActions.fetchAuctions.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.fetchAuctions.Failure,
      payload: errors,
    });
  }
}
export function* fetchMyAuctions({ payload }: FetchAuctionsActionType) {
  const { page, pageSize } = payload;
  try {
    const { data } = yield call(
      ({ page, pageSize }: Omit<FetchAuctionsActionPayload, "filter">) => auctionsApi.myAuctions(pageSize, page),
      { page, pageSize }
    );

    yield put({
      type: auctionsActions.fetchMyAuctions.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.fetchMyAuctions.Failure,
      payload: errors,
    });
  }
}
export function* fetchMyBids({ payload }: FetchAuctionsActionType) {
  const { page, pageSize } = payload;
  try {
    const { data } = yield call(
      ({ page, pageSize }: Omit<FetchAuctionsActionPayload, "filter">) => auctionsApi.myBids(pageSize, page),
      { page, pageSize }
    );

    yield put({
      type: auctionsActions.fetchMyBids.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.fetchMyBids.Failure,
      payload: errors,
    });
  }
}
export function* debounceSearchAction({ payload }: SearchAuctionsActionType) {
  try {
    const { data } = yield call(({ filter: { term, categories }, page, pageSize }) => {
      return auctionsApi.search(term!, (categories || []).join(","), page, pageSize);
    }, payload);

    yield put({
      type: auctionsActions.searchAction.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);

    yield put({
      type: auctionsActions.searchAction.Failure,
      payload: errors,
    });
  }
}

export function* searchAction() {
  yield debounce(1000, "DEBOUNCE_SEARCH_AUCTIONS", debounceSearchAction);
}

export function* fetchAuction({ payload: { auctionId } }: FetchAuctionActionType) {
  try {
    const { data } = yield call((auctionId) => auctionApi.auction(auctionId), auctionId);

    yield put({
      type: auctionsActions.fetchAuction.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);

    yield put({
      type: auctionsActions.fetchAuction.Failure,
      payload: errors,
    });
  }
}
export function* clearAuction() {
  yield put({ type: auctionsActions.clearAuction.Success });
}

export function* updateAuction({ payload: { auctionId, updatedAuction }, navigate }: UpdateAuctionActionType) {
  try {
    const { data } = yield call(
      (updatedAuction) => auctionApi.updateAuction(auctionId, updatedAuction),
      updatedAuction
    );

    yield put({
      type: auctionsActions.createAuction.Success,
      payload: data,
    });

    toast("Auction successfully updated!", "success");
    navigate(`/auction/${data.resource.id}`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.createAuction.Failure,
      payload: errors,
    });
  }
}

export function* createNewAuction({ payload: { newAuction }, navigate }: CreateAuctionActionType) {
  try {
    const { data } = yield call((newAuction) => auctionApi.createAuction(newAuction), newAuction);
    yield put({
      type: auctionsActions.createAuction.Success,
      payload: data,
    });
    toast("Auction successfully added!", "success");
    navigate(`/auction/${data.resource.id}`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.createAuction.Failure,
      payload: errors,
    });
  }
}

export function* deleteAuction({ payload: { auctionId }, navigate }: DeleteAuctionActionType) {
  try {
    yield call((auctionId) => auctionApi.removeAuction(auctionId), auctionId);
    toast("Auction successfully deleted!", "success");
    navigate(`/auctions`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.deleteAuction.Failure,
      payload: errors,
    });
  }
}

export function* setAuction(action: SetAuctionToAuctionActionType) {
  const { auctionId } = action.payload;

  try {
    yield call((auctionId) => auctionApi.activate(auctionId), auctionId);
    toast("Auction successfully set to auction!", "success");
    action.navigate(`/auctions`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.deleteAuction.Failure,
      payload: errors,
    });
  }
}

export function* uploadAuctionCover({ payload: { formData } }: UploadCoverActionType) {
  /*
  try {
    const { data } = yield call((formData) => mediaApi.updateMedia(formData), formData);
    toast("Auction cover successfully uploaded!", "success");

    yield put(change(FORMS.FORM_AUCTION_EDIT, "image", data.file.fileName));
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.uploadCover.Failure,
      payload: errors,
    });
  }
  */
}

export function* executeOrder({ payload: { auctionId }, navigate }: ExecuteAuctionActionType) {
  try {
    const { data } = yield call(Api.executeOrder, { auctionId });
    yield put({
      type: auctionsActions.executeOrder.Success,
      payload: data,
    });
    navigate(`/auction/${auctionId}`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.executeOrder.Failure,
      payload: errors,
    });
  }
}

export function* receiveOrder({ payload: { auctionId }, navigate }: ReceiveAuctionActionType) {
  try {
    const { data } = yield call(Api.receiveOrder, { auctionId });
    yield put({
      type: auctionsActions.receiveOrder.Success,
      payload: data,
    });
    navigate(`/auction/${auctionId}`);
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: auctionsActions.receiveOrder.Failure,
      payload: errors,
    });
  }
}
