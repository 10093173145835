import { Reducer } from "redux";
import { ActionType } from "../../interfaces/actionTypes";
import BidInterface from "../../interfaces/bid";
import * as bidsActions from "./actions";


export interface BidsStateInterface {
  resources: BidInterface[];
  meta: {
    page: number;
    pageSize: number;
    total: number;
  };
  isLoading: boolean;
}

export const bidsInitialState: BidsStateInterface = {
  resources: [],
  meta: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  isLoading: false,
};

export const bidsReducers: Reducer = (
  bidsState: BidsStateInterface = bidsInitialState,
  { type, payload }: ActionType
): BidsStateInterface => {
  switch (type) {
    case bidsActions.FETCH_BIDS.Request:
      return { ...bidsState, isLoading: true };

    case bidsActions.FETCH_BIDS.Success:
      if (payload && payload.resources) {
        return {
          resources: [...payload.resources],
          meta: payload.meta,
          isLoading: false,
        };
      }
      return { ...bidsInitialState };

    case bidsActions.FETCH_BIDS.Failure:
      return { ...bidsInitialState };

    default:
      return { ...bidsState };
  }
};
