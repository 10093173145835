import { toast as toastNative } from "react-toastify";
import BidInterface from "../interfaces/bid";
import ResponseErrorInterface from "../interfaces/responseError";

export const toast = (msg: string, type = ""): void => {
  switch (type) {
    case "success":
      toastNative.success(msg, {
        position: "top-right",
      });
      break;
    case "info":
      toastNative.info(msg, {
        position: "top-right",
      });
      break;
    case "warn":
      toastNative.warn(msg, {
        position: "top-right",
      });
      break;
    default:
      toastNative.error(msg, {
        position: "top-right",
      });
  }
};

// response is payload object from saga to reducer
export const showAxiosErrors = (response: ResponseErrorInterface): void => {
  // todo
  if (response && response.data && Array.isArray(response.data)) {
    response.data.forEach((errObj) => {
      toast(errObj.message);
      return;
    });
    return;
  }

  if (response && response.data && response.data.message) {
    toast(response.data.message);
    return;
  }

  if (response && response.data && response.data.error) {
    toast(response.data.error);
    return;
  }

  if (Array.isArray(response)) {
    response.forEach((errObj) => {
      toast(errObj.message);
      return;
    });
    return;
  }

  if (response && response.message) {
    toast(response.message);
    return;
  }

  toast("Unknown server error");
};

export const getWinnersBid = (bids: BidInterface[] | null): BidInterface | undefined => {
  if (!bids) {
    return;
  }
  const arr: number[] = bids.map((b) => b.proposedPrice);
  const maxPrice = Math.max(...arr);
  return bids.find((b) => b.proposedPrice === maxPrice);
};
