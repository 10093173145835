/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from "typed-redux-saga";
import * as siteActions from "../../domain/site/actions";
import { siteApi } from "../../libs/api";
import { showAxiosErrors } from "../../libs/helpers";
import { FetchSiteActionType } from "./../../interfaces/actionTypes";

export function* fetchSite(empty: FetchSiteActionType) {
  try {
    const { data } = yield call(() => siteApi.settings());
    yield put({
      type: siteActions.FETCH_SITE.Success,
      payload: data,
    });
  } catch (error: any) {
    showAxiosErrors(error.response);
    yield put({ type: siteActions.FETCH_SITE.Failure, payload: error });
  }
}
