import i18next from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "filter.where": "Where",
      "filter.what": "What",
      "filter.how": "How",
      "filter.hierarchicalLocations.lvl0": "Location:",
      "filter.hierarchicalCategories.lvl0": "Category:",
      "filter.delivery": "Delivery:",
    },
  },
  sv: {
    translation: {
      "filter.where": "Vart",
      "filter.what": "Vad",
      "filter.how": "Hur",
      "filter.hierarchicalLocations.lvl0": "Ort",
      "filter.hierarchicalCategories.lvl0": "Kategori",
      "filter.delivery": "Leveranssätt",
    },
  },
};
i18next.use(initReactI18next).init({
  debug: process.env.ENV === "dev",
  resources,
  fallbackLng: "en",
  supportedLngs: ["en", "sv"],
});

export default i18n;
