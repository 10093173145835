export const SUFFIX = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  EVENT: "EVENT",
};

interface ActionCreator {
  Request: string;
  Success: string;
  Failure: string;
}
interface EventCreator {
  Event: string;
}
export function getAction(actionName: string): ActionCreator {
  return {
    Request: `${actionName}/${SUFFIX.PENDING}`,
    Success: `${actionName}/${SUFFIX.SUCCESS}`,
    Failure: `${actionName}/${SUFFIX.FAILURE}`,
  };
}
export function getEvent(eventName: string): EventCreator {
  return {
    Event: `${eventName}/${SUFFIX.EVENT}`,
  };
}
