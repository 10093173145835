import { Reducer } from "redux";
import { ActionType } from "../../interfaces/actionTypes";
import OrderInterface from "../../interfaces/order";
import { Meta } from "./../index";
import * as ordersActions from "./actions";


export interface OrderState {
  resources: OrderInterface[];
  meta: Meta;
  isLoading: boolean;
}

export const ordersInitialState: OrderState = {
  resources: [],
  meta: {} as Meta,
  isLoading: false,
};

export const ordersReducers: Reducer = (ordersState: OrderState = ordersInitialState, { type, payload }: ActionType): OrderState => {
  switch (type) {
    case ordersActions.FETCH_ORDERS.Request: {
      return { ...ordersInitialState, isLoading: true };
    }

    case ordersActions.FETCH_ORDERS.Success:
      return {
        resources: [...payload.resources],
        meta: payload.meta,
        isLoading: false,
      };

    case ordersActions.FETCH_ORDERS.Failure:
      return { ...ordersState, isLoading: false };

    default:
      return { ...ordersState };
  }
};
