/* tslint:disable */
/* eslint-disable */
/**
 * Auctioneer API
 * Auctioneer API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuctionDto
 */
export interface AuctionDto {
    /**
     * 
     * @type {number}
     * @memberof AuctionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    'title': string;
    /**
     * 
     * @type {object}
     * @memberof AuctionDto
     */
    'cover'?: object;
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuctionDto
     */
    'askPrice': number;
    /**
     * 
     * @type {number}
     * @memberof AuctionDto
     */
    'buyoutPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof AuctionDto
     */
    'user': UserDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof AuctionDto
     */
    'medias': Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuctionDto
     */
    'delivery': Array<AuctionDtoDeliveryEnum>;
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    'location'?: string;
}

export const AuctionDtoDeliveryEnum = {
    Pickup: 'pickup',
    Shipping: 'shipping'
} as const;

export type AuctionDtoDeliveryEnum = typeof AuctionDtoDeliveryEnum[keyof typeof AuctionDtoDeliveryEnum];

/**
 * 
 * @export
 * @interface AuctionsDto
 */
export interface AuctionsDto {
    /**
     * 
     * @type {Array<AuctionDto>}
     * @memberof AuctionsDto
     */
    'resources': Array<AuctionDto>;
    /**
     * 
     * @type {PaginationDto}
     * @memberof AuctionsDto
     */
    'pagination': PaginationDto;
}
/**
 * 
 * @export
 * @interface BidDto
 */
export interface BidDto {
    /**
     * 
     * @type {number}
     * @memberof BidDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BidDto
     */
    'bidCreationTime': string;
    /**
     * 
     * @type {number}
     * @memberof BidDto
     */
    'proposedPrice': number;
    /**
     * 
     * @type {UserDto}
     * @memberof BidDto
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'name': string;
    /**
     * 
     * @type {CategoryDto}
     * @memberof CategoryDto
     */
    'parent'?: CategoryDto;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof CategoryDto
     */
    'children': Array<CategoryDto>;
}
/**
 * 
 * @export
 * @interface ClientSecretDto
 */
export interface ClientSecretDto {
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface CreateAuctionDto
 */
export interface CreateAuctionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAuctionDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuctionDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAuctionDto
     */
    'askPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAuctionDto
     */
    'buyoutPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAuctionDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateAuctionDto
     */
    'medias': Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAuctionDto
     */
    'delivery': Array<CreateAuctionDtoDeliveryEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateAuctionDto
     */
    'location'?: string;
}

export const CreateAuctionDtoDeliveryEnum = {
    Pickup: 'pickup',
    Shipping: 'shipping'
} as const;

export type CreateAuctionDtoDeliveryEnum = typeof CreateAuctionDtoDeliveryEnum[keyof typeof CreateAuctionDtoDeliveryEnum];

/**
 * 
 * @export
 * @interface CreateBid
 */
export interface CreateBid {
    /**
     * 
     * @type {number}
     * @memberof CreateBid
     */
    'bid': number;
}
/**
 * 
 * @export
 * @interface CreateMediaDto
 */
export interface CreateMediaDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMediaDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaDto
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlDto
 */
export interface GetSignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface OrderState
 */
export interface OrderState {
    /**
     * 
     * @type {string}
     * @memberof OrderState
     */
    'arrivalLocation': string;
    /**
     * 
     * @type {string}
     * @memberof OrderState
     */
    'type': OrderStateTypeEnum;
}

export const OrderStateTypeEnum = {
    Pending: 'pending',
    RoyalMail: 'Royal Mail',
    UnitedStatesPostalService: 'United States Postal Service',
    DhlExpress: 'DHL Express'
} as const;

export type OrderStateTypeEnum = typeof OrderStateTypeEnum[keyof typeof OrderStateTypeEnum];

/**
 * 
 * @export
 * @interface PageOption
 */
export interface PageOption {
    /**
     * 
     * @type {number}
     * @memberof PageOption
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageOption
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PaginationDto
 */
export interface PaginationDto {
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SearchSiteDto
 */
export interface SearchSiteDto {
    /**
     * 
     * @type {number}
     * @memberof SearchSiteDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SearchSiteDto
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof SearchSiteDto
     */
    'query'?: string;
}
/**
 * 
 * @export
 * @interface SearchSitesDto
 */
export interface SearchSitesDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof SearchSitesDto
     */
    'items': Array<object>;
    /**
     * 
     * @type {PaginationDto}
     * @memberof SearchSitesDto
     */
    'pagination': PaginationDto;
}
/**
 * 
 * @export
 * @interface SignedUrlDto
 */
export interface SignedUrlDto {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlDto
     */
    'signedUrl': string;
}
/**
 * 
 * @export
 * @interface SiteDto
 */
export interface SiteDto {
    /**
     * 
     * @type {number}
     * @memberof SiteDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SiteDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SiteDto
     */
    'name': string;
    /**
     * 
     * @type {CategoryDto}
     * @memberof SiteDto
     */
    'categoryTree': CategoryDto;
    /**
     * 
     * @type {string}
     * @memberof SiteDto
     */
    'searchKey': string;
    /**
     * 
     * @type {string}
     * @memberof SiteDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UpdateAuctionDto
 */
export interface UpdateAuctionDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateAuctionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuctionDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuctionDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAuctionDto
     */
    'askPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAuctionDto
     */
    'buyoutPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuctionDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAuctionDto
     */
    'medias': Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAuctionDto
     */
    'delivery': Array<UpdateAuctionDtoDeliveryEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuctionDto
     */
    'location'?: string;
}

export const UpdateAuctionDtoDeliveryEnum = {
    Pickup: 'pickup',
    Shipping: 'shipping'
} as const;

export type UpdateAuctionDtoDeliveryEnum = typeof UpdateAuctionDtoDeliveryEnum[keyof typeof UpdateAuctionDtoDeliveryEnum];

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
}

/**
 * AuctionApi - axios parameter creator
 * @export
 */
export const AuctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (auctionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('activate', 'auctionId', auctionId)
            const localVarPath = `/v1/auction/{auctionId}/activate`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auction: async (auctionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('auction', 'auctionId', auctionId)
            const localVarPath = `/v1/auction/{auctionId}`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bids: async (auctionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('bids', 'auctionId', auctionId)
            const localVarPath = `/v1/auction/{auctionId}/bids`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateAuctionDto} createAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuction: async (createAuctionDto: CreateAuctionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuctionDto' is not null or undefined
            assertParamExists('createAuction', 'createAuctionDto', createAuctionDto)
            const localVarPath = `/v1/auction/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuctionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {CreateBid} createBid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeBid: async (auctionId: number, createBid: CreateBid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('placeBid', 'auctionId', auctionId)
            // verify required parameter 'createBid' is not null or undefined
            assertParamExists('placeBid', 'createBid', createBid)
            const localVarPath = `/v1/auction/{auctionId}/bid`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuction: async (auctionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('removeAuction', 'auctionId', auctionId)
            const localVarPath = `/v1/auction/{auctionId}`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {UpdateAuctionDto} updateAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuction: async (auctionId: number, updateAuctionDto: UpdateAuctionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'auctionId' is not null or undefined
            assertParamExists('updateAuction', 'auctionId', auctionId)
            // verify required parameter 'updateAuctionDto' is not null or undefined
            assertParamExists('updateAuction', 'updateAuctionDto', updateAuctionDto)
            const localVarPath = `/v1/auction/{auctionId}`
                .replace(`{${"auctionId"}}`, encodeURIComponent(String(auctionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuctionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuctionApi - functional programming interface
 * @export
 */
export const AuctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(auctionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(auctionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auction(auctionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auction(auctionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bids(auctionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BidDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bids(auctionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateAuctionDto} createAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuction(createAuctionDto: CreateAuctionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuction(createAuctionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {CreateBid} createBid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async placeBid(auctionId: number, createBid: CreateBid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.placeBid(auctionId, createBid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAuction(auctionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAuction(auctionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {UpdateAuctionDto} updateAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuction(auctionId: number, updateAuctionDto: UpdateAuctionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuction(auctionId, updateAuctionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuctionApi - factory interface
 * @export
 */
export const AuctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuctionApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(auctionId: number, options?: any): AxiosPromise<AuctionDto> {
            return localVarFp.activate(auctionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auction(auctionId: number, options?: any): AxiosPromise<AuctionDto> {
            return localVarFp.auction(auctionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bids(auctionId: number, options?: any): AxiosPromise<Array<BidDto>> {
            return localVarFp.bids(auctionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateAuctionDto} createAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuction(createAuctionDto: CreateAuctionDto, options?: any): AxiosPromise<AuctionDto> {
            return localVarFp.createAuction(createAuctionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {CreateBid} createBid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        placeBid(auctionId: number, createBid: CreateBid, options?: any): AxiosPromise<BidDto> {
            return localVarFp.placeBid(auctionId, createBid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuction(auctionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeAuction(auctionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} auctionId 
         * @param {UpdateAuctionDto} updateAuctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuction(auctionId: number, updateAuctionDto: UpdateAuctionDto, options?: any): AxiosPromise<AuctionDto> {
            return localVarFp.updateAuction(auctionId, updateAuctionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuctionApi - object-oriented interface
 * @export
 * @class AuctionApi
 * @extends {BaseAPI}
 */
export class AuctionApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public activate(auctionId: number, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).activate(auctionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auction(auctionId: number, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).auction(auctionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public bids(auctionId: number, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).bids(auctionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateAuctionDto} createAuctionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public createAuction(createAuctionDto: CreateAuctionDto, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).createAuction(createAuctionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {CreateBid} createBid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public placeBid(auctionId: number, createBid: CreateBid, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).placeBid(auctionId, createBid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public removeAuction(auctionId: number, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).removeAuction(auctionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} auctionId 
     * @param {UpdateAuctionDto} updateAuctionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public updateAuction(auctionId: number, updateAuctionDto: UpdateAuctionDto, options?: AxiosRequestConfig) {
        return AuctionApiFp(this.configuration).updateAuction(auctionId, updateAuctionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuctionsApi - axios parameter creator
 * @export
 */
export const AuctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * auctions index page get auctions: status in InProcess, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {string} [categories] comma separated list of id\&#39;s
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAuctions: async (categories?: string, pageSize?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * auth user own auctions page get auctions: auction.user.id == jwt.user.id, paginating params response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAuctions: async (pageSize?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auctions/my/auctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user bidded auctions page get auctions: auction.bid.user.id == jwt.user.id, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myBids: async (pageSize?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auctions/my/bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} term 
         * @param {string} [categories] Comma separated list of category ids
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (term: string, categories?: string, pageSize?: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            assertParamExists('search', 'term', term)
            const localVarPath = `/v1/auctions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuctionsApi - functional programming interface
 * @export
 */
export const AuctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * auctions index page get auctions: status in InProcess, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {string} [categories] comma separated list of id\&#39;s
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAuctions(categories?: string, pageSize?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAuctions(categories, pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * auth user own auctions page get auctions: auction.user.id == jwt.user.id, paginating params response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myAuctions(pageSize?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myAuctions(pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * user bidded auctions page get auctions: auction.bid.user.id == jwt.user.id, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myBids(pageSize?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myBids(pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} term 
         * @param {string} [categories] Comma separated list of category ids
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(term: string, categories?: string, pageSize?: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuctionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(term, categories, pageSize, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuctionsApi - factory interface
 * @export
 */
export const AuctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuctionsApiFp(configuration)
    return {
        /**
         * auctions index page get auctions: status in InProcess, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {string} [categories] comma separated list of id\&#39;s
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAuctions(categories?: string, pageSize?: any, page?: any, options?: any): AxiosPromise<AuctionsDto> {
            return localVarFp.allAuctions(categories, pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * auth user own auctions page get auctions: auction.user.id == jwt.user.id, paginating params response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAuctions(pageSize?: any, page?: any, options?: any): AxiosPromise<AuctionsDto> {
            return localVarFp.myAuctions(pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * user bidded auctions page get auctions: auction.bid.user.id == jwt.user.id, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
         * @summary 
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myBids(pageSize?: any, page?: any, options?: any): AxiosPromise<AuctionsDto> {
            return localVarFp.myBids(pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} term 
         * @param {string} [categories] Comma separated list of category ids
         * @param {any} [pageSize] 
         * @param {any} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(term: string, categories?: string, pageSize?: any, page?: any, options?: any): AxiosPromise<AuctionsDto> {
            return localVarFp.search(term, categories, pageSize, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuctionsApi - object-oriented interface
 * @export
 * @class AuctionsApi
 * @extends {BaseAPI}
 */
export class AuctionsApi extends BaseAPI {
    /**
     * auctions index page get auctions: status in InProcess, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
     * @summary 
     * @param {string} [categories] comma separated list of id\&#39;s
     * @param {any} [pageSize] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionsApi
     */
    public allAuctions(categories?: string, pageSize?: any, page?: any, options?: AxiosRequestConfig) {
        return AuctionsApiFp(this.configuration).allAuctions(categories, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * auth user own auctions page get auctions: auction.user.id == jwt.user.id, paginating params response serialisation in AuctionsSerializerInterceptor
     * @summary 
     * @param {any} [pageSize] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionsApi
     */
    public myAuctions(pageSize?: any, page?: any, options?: AxiosRequestConfig) {
        return AuctionsApiFp(this.configuration).myAuctions(pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user bidded auctions page get auctions: auction.bid.user.id == jwt.user.id, paginating params in @Request() request response serialisation in AuctionsSerializerInterceptor
     * @summary 
     * @param {any} [pageSize] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionsApi
     */
    public myBids(pageSize?: any, page?: any, options?: AxiosRequestConfig) {
        return AuctionsApiFp(this.configuration).myBids(pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} term 
     * @param {string} [categories] Comma separated list of category ids
     * @param {any} [pageSize] 
     * @param {any} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionsApi
     */
    public search(term: string, categories?: string, pageSize?: any, page?: any, options?: AxiosRequestConfig) {
        return AuctionsApiFp(this.configuration).search(term, categories, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategory', 'id', id)
            const localVarPath = `/v1/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTree', 'id', id)
            const localVarPath = `/v1/category/{id}/tree`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTree(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTree(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.getCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree(id: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.getTree(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategory(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getTree(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getTree(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateMediaDto} createMediaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMedia: async (createMediaDto: CreateMediaDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMediaDto' is not null or undefined
            assertParamExists('createMedia', 'createMediaDto', createMediaDto)
            const localVarPath = `/v1/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMediaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        data: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/media/data/*`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedia: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMedia', 'id', id)
            const localVarPath = `/v1/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMedia', 'id', id)
            const localVarPath = `/v1/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multerUpload: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/media/multer-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {GetSignedUrlDto} getSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signedUrl: async (getSignedUrlDto: GetSignedUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignedUrlDto' is not null or undefined
            assertParamExists('signedUrl', 'getSignedUrlDto', getSignedUrlDto)
            const localVarPath = `/v1/media/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedia: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMedia', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMedia', 'body', body)
            const localVarPath = `/v1/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateMediaDto} createMediaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMedia(createMediaDto: CreateMediaDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMedia(createMediaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async data(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.data(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedia(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedia(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedia(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedia(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multerUpload(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multerUpload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {GetSignedUrlDto} getSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signedUrl(getSignedUrlDto: GetSignedUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signedUrl(getSignedUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMedia(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMedia(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateMediaDto} createMediaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMedia(createMediaDto: CreateMediaDto, options?: any): AxiosPromise<object> {
            return localVarFp.createMedia(createMediaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        data(options?: any): AxiosPromise<object> {
            return localVarFp.data(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedia(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMedia(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getMedia(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaFindAll(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.mediaFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multerUpload(file?: File, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.multerUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {GetSignedUrlDto} getSignedUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signedUrl(getSignedUrlDto: GetSignedUrlDto, options?: any): AxiosPromise<SignedUrlDto> {
            return localVarFp.signedUrl(getSignedUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedia(id: string, body: object, options?: any): AxiosPromise<string> {
            return localVarFp.updateMedia(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateMediaDto} createMediaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public createMedia(createMediaDto: CreateMediaDto, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).createMedia(createMediaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public data(options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).data(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public deleteMedia(id: string, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).deleteMedia(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMedia(id: string, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).getMedia(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaFindAll(options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public multerUpload(file?: File, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).multerUpload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {GetSignedUrlDto} getSignedUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public signedUrl(getSignedUrlDto: GetSignedUrlDto, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).signedUrl(getSignedUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public updateMedia(id: string, body: object, options?: AxiosRequestConfig) {
        return MediaApiFp(this.configuration).updateMedia(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('execute', 'orderId', orderId)
            const localVarPath = `/v1/order/{orderId}/execute`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        order: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('order', 'orderId', orderId)
            const localVarPath = `/v1/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCreateOrder: async (orderId: number, orderState: OrderState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderCreateOrder', 'orderId', orderId)
            // verify required parameter 'orderState' is not null or undefined
            assertParamExists('orderCreateOrder', 'orderState', orderState)
            const localVarPath = `/v1/order/{orderId}/create`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrder: async (orderId: number, orderState: OrderState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdateOrder', 'orderId', orderId)
            // verify required parameter 'orderState' is not null or undefined
            assertParamExists('orderUpdateOrder', 'orderState', orderState)
            const localVarPath = `/v1/order/{orderId}/update`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receive: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('receive', 'orderId', orderId)
            const localVarPath = `/v1/order/{orderId}/receive`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async execute(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.execute(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async order(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.order(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCreateOrder(orderId: number, orderState: OrderState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCreateOrder(orderId, orderState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdateOrder(orderId: number, orderState: OrderState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdateOrder(orderId, orderState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receive(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receive(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute(orderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.execute(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        order(orderId: number, options?: any): AxiosPromise<object> {
            return localVarFp.order(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCreateOrder(orderId: number, orderState: OrderState, options?: any): AxiosPromise<object> {
            return localVarFp.orderCreateOrder(orderId, orderState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderOrders(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.orderOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {OrderState} orderState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateOrder(orderId: number, orderState: OrderState, options?: any): AxiosPromise<object> {
            return localVarFp.orderUpdateOrder(orderId, orderState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receive(orderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.receive(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public execute(orderId: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).execute(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public order(orderId: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).order(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {OrderState} orderState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderCreateOrder(orderId: number, orderState: OrderState, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderCreateOrder(orderId, orderState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderOrders(options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {OrderState} orderState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUpdateOrder(orderId: number, orderState: OrderState, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUpdateOrder(orderId, orderState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public receive(orderId: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).receive(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment/hook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment/intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async intent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSecretDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.intent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hook(options?: any): AxiosPromise<void> {
            return localVarFp.hook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        intent(options?: any): AxiosPromise<ClientSecretDto> {
            return localVarFp.intent(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public hook(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).hook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public intent(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).intent(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/v1/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/v1/site`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/site/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-site-key required
            await setApiKeyToObject(localVarHeaderParameter, "x-site-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SearchSiteDto} searchSiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteSearch: async (searchSiteDto: SearchSiteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchSiteDto' is not null or undefined
            assertParamExists('siteSearch', 'searchSiteDto', searchSiteDto)
            const localVarPath = `/v1/site/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchSiteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/v1/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SearchSiteDto} searchSiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteSearch(searchSiteDto: SearchSiteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchSitesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteSearch(searchSiteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: object, options?: any): AxiosPromise<object> {
            return localVarFp.create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settings(options?: any): AxiosPromise<SiteDto> {
            return localVarFp.settings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SearchSiteDto} searchSiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteSearch(searchSiteDto: SearchSiteDto, options?: any): AxiosPromise<SearchSitesDto> {
            return localVarFp.siteSearch(searchSiteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.update(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public _delete(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public create(body: object, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public settings(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).settings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SearchSiteDto} searchSiteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteSearch(searchSiteDto: SearchSiteDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).siteSearch(searchSiteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public update(id: string, body: object, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).update(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('user', 'userId', userId)
            const localVarPath = `/v1/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile(options?: any): AxiosPromise<object> {
            return localVarFp.profile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(userId: number, options?: any): AxiosPromise<object> {
            return localVarFp.user(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public profile(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).profile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public user(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).user(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


