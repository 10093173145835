import { useCurrentRefinements, useHierarchicalMenu } from "react-instantsearch";

import React from "react";
import type { HierarchicalMenuItem } from "instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu";
import styles from "./aloglia.refinement.menu.module.css";
type AuxrefinementMenuProps = {
  items: HierarchicalMenuItem[];
  refine: (value: string) => void;
  sub?: boolean;
};
export const AuxRefinementMenu = ({ items, refine, sub = false }: AuxrefinementMenuProps) => {
  const { items: refined, refine: removeRefinement } = useCurrentRefinements();
  return (
    <ul className={sub ? styles.pl10 : ""}>
      {items.map((item) => (
        <li key={item.value}>
          <label>
            <input
              type="checkbox"
              value={item.value}
              checked={item.isRefined}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  refine(e.currentTarget.value);
                } else {
                  refined
                    .map((refinement) => refinement.refinements)
                    .flat()
                    .filter((refinement) => refinement.value === e.target.value)
                    .map((refinement) => removeRefinement(refinement));
                }
              }}
            />
            {item.label} <span style={{ float: "right" }}>({item.count})</span>
          </label>
          {item?.data?.length && <AuxRefinementMenu items={item.data} refine={refine} sub={true}/>}
        </li>
      ))}
    </ul>
  );
};
