import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import configureStore from "./domain/index";
import rootSaga from "./sagas/index";
// components
import NavBar from "./components/navbar";
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import { Outlet, RouterProvider, createHashRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useSettings } from "./gen/rq";
import { useAuxStore } from "./stores/aux.store";
import { AXIOS_INSTANCE } from "./gen/custom-axios";
import "./i18n";
import { QueryClientProvider } from "@tanstack/react-query";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { WebStorageStateStore } from "oidc-client-ts";
import Switcher from "./Switcher";
import { QueryClient } from "@tanstack/react-query";
import i18n from "./i18n";
const siteKey = process.env.REACT_APP_SITE_KEY || "";

AXIOS_INSTANCE.interceptors.request.use((request) => {
  request.headers["x-site-key"] = window?.auctioneerConfig?.siteKey || siteKey;
  return request;
});

export const App: React.FC = () => {
  const auth = useAuth();

  const { setSite, site } = useAuxStore();

  AXIOS_INSTANCE.interceptors.request.use((request) => {
    if (!!auth.user?.access_token) {
      request.headers["Authorization"] = `bearer ${auth.user.access_token}`;
    }
    return request;
  });

  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  // automatically sign-in
  /*
  React.useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);
  */

  const { isLoading, isSuccess, data } = useSettings();
  useEffect(() => {
    if (isSuccess) setSite(data);
  }, [setSite, isSuccess, data]);

  return (
    <>
      <ToastContainer />
      <NavBar />
      <Outlet />
      {/*<StickyFooter />*/}
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const store = configureStore();
store.runSaga(rootSaga);
const oidcConfig: AuthProviderProps = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: `${process.env.KEYCLOAK_URL || "https://sso.local.niklasblomberg.se"}/realms/${
    process.env.KEYCLOAK_REALM || "auctioneer-staging"
  }`, // replace your-keycloak and your-realm
  client_id: `${process.env.KEYCLOAK_CLIENT_ID || "auctioneer-staging"}`,
  redirect_uri: `${process.env.PUBLIC_URL || "https://localhost:444"}`, // replace this with the URL of your application, probably injected depending on the environment...
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const router = createHashRouter([{ path: "*", Component: Switcher }]);
const queryClient = new QueryClient();

export const AppContainer = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider {...oidcConfig}>
          <I18nextProvider i18n={i18n}>
            <RouterProvider router={router} />
          </I18nextProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
};
