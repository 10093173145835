import React from "react";
import { UseHitsProps } from "react-instantsearch";
import { useHits } from "react-instantsearch";
import AlgoliaCard from "./algolia.card";

const AlgoliaItems = (props: UseHitsProps) => {
  const { items } = useHits(props);
  return (
    <>
      {items.map((item) => (
        <AlgoliaCard item={item} key={item.objectID} />
      ))}
    </>
  );
};

export default AlgoliaItems;
