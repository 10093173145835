/*
import AuctionButtons from "./components/auctionButtons";
import "./styles/auctionDetailsStyles.scss";
*/
import React, { useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Commento from "../../components/commento";
import AuctionToolbar from "../../components/auctions/auction.toolbar";
import BidHistory from "../../components/bids/bid.history";
import { useParams } from "react-router-dom";
import { MediaDto, getAuctionQueryOptions, useAuction, usePlaceBid } from "../../gen/rq";
import { AuctionDto } from "../../gen/axios-api";
import { AxiosResponse } from "axios";
interface AuctionDetailsProps {}
const AuctionDetails: React.FC<AuctionDetailsProps> = () => {
  const auctionId = +(useParams().auctionId || "0");

  const [bid, setBid] = useState(-1);
  // eslint-disable-next-line
  if (auctionId < 0) {
    return <p> Auction with id {auctionId} not found</p>;
  }
  const { data, isSuccess } = useAuction(auctionId);

  const placeBid = usePlaceBid().mutate;
  return isSuccess && data ? (
    <article key={`auction-${data.id}`}>
      <header>
        <button aria-label="Close" rel="prev"></button>
        {data.title}
        <AuctionToolbar />
      </header>
      <section className="grid">
        <div>
          {data.medias.length ? (
            <ImageGallery
              items={(data.medias || []).map(
                (media: MediaDto) =>
                  ({ original: `${media.url}/1280/720`, thumbnail: `${media.url}/128/128` }) as ReactImageGalleryItem,
              )}
            />
          ) : null}
          {/*
            showThumbnails={images.length > 1}
            showPlayButton={false}
            showFullscreenButton={false}
            */}
        </div>
        <div>
          <div aria-label="auction price">
            <label htmlFor="bid">Amount</label>
            <fieldset role="group">
              <input id="bid" name="bid" defaultValue={bid} onChange={(e) => setBid(+e.target.value)} />
              <button aria-label="place an bid" onClick={async () => placeBid({ auctionId, data: { bid } })}>
                Bid
              </button>
            </fieldset>
          </div>
          <div>
            <BidHistory auctionId={auctionId}></BidHistory>
          </div>
        </div>
      </section>
      <section>
        <p>{data.description}</p>
      </section>
      <hr />
      <section>
        <details>
          <summary>Comments</summary>
          <div id="commento">
            <Commento id="commento" jsUrl="https://commento.local.niklasblomberg.se/js/commento.js" />
          </div>
        </details>
      </section>
      <footer>
        by {data.user?.firstName} {data.user?.firstName}
      </footer>
    </article>
  ) : (
    <p> Auction could not be bound, either closed or removed</p>
  );
};

export default AuctionDetails;
