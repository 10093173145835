import React, { useEffect, useState } from "react";
import { BidDto, useBids } from "../../gen/rq";
import { AxiosResponse } from "axios";
interface BidHistoryProps {
  auctionId: number;
}

const BidHistory: React.FC<BidHistoryProps> = ({ auctionId }) => {
  const { data, isSuccess, isLoading } = useBids(auctionId);
  const [highest, setHighest] = useState<BidDto | undefined>();
  const [history, setHistory] = useState<BidDto[]>([]);
  useEffect(() => {
    if (isSuccess) setHighest(data[0]);
  }, [data]);
  return isSuccess || !isLoading ? (
    <>
      {highest ? (
        <div>
          <h3>Highest bid: {highest.proposedPrice}kr</h3>
          <small>by </small>
        </div>
      ) : null}
      <details open>
        <summary></summary>
        {highest ? (
          <>
            <table>
              <thead>
                <tr>
                  <th colSpan={3}></th>
                </tr>
              </thead>
              {history.length ? (
                <tbody>
                  {history.map((bid: BidDto) => (
                    <tr key={`bid-${bid.id}`}>
                      <td>{bid.proposedPrice} kr</td>
                      <td>{bid.user?.firstName}</td>
                      <td>{bid.bidCreationTime}</td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
            <button type="button" value="Show all" />
          </>
        ) : (
          <p>No bids, be the first!</p>
        )}
      </details>
    </>
  ) : (
    <i className="fa fa-spin" />
  );
};

export default BidHistory;
