import { Reducer } from "redux";
import { CreateAuctionDto } from "../../gen/axios-api";
import { ActionType } from "../../interfaces/actionTypes";
import AuctionInterface from "../../interfaces/auction";
import BidInterface from "../../interfaces/bid";
import MediaInterface from "../../interfaces/media";
import { UserInterface } from "./../../interfaces/user";
import * as auctionsActions from "./actions";

export interface AuctionsStateInterface {
  resources: AuctionInterface[];
  resource: AuctionInterface;
  filter: {
    term: string;
    categories: number[];
  };
  createAuction: CreateAuctionDto;
  meta: {
    page: number;
    pageSize: number;
    total: number;
  };
  isLoading: boolean;
  displayGrid: boolean;
}

export const auctionsInitialState: AuctionsStateInterface = {
  createAuction: {
    delivery: [],
    title: "",
    askPrice: 0,
    endTime: "",
    medias: [],
    buyoutPrice: -1,
  },
  resources: [],
  resource: {
    id: 0,
    title: "",
    cover: {} as MediaInterface,
    description: "",
    status: "",
    createAt: "",
    currentPrice: 0,
    estimatedPrice: 0,
    endTime: "",
    user: {} as UserInterface,
    bids: [] as BidInterface[],
    medias: [] as MediaInterface[],
  },
  filter: {
    term: "",
    categories: [],
  },
  meta: {
    page: 1,
    pageSize: 24,
    total: 0,
  },

  displayGrid: true,
  isLoading: false,
};

export const auctionsReducers: Reducer = (
  auctionsState: AuctionsStateInterface = auctionsInitialState,
  { type, payload }: ActionType
): AuctionsStateInterface => {
  switch (type) {
    case auctionsActions.searchTermChange.Event:
      return {
        ...auctionsState,
        filter: {
          ...auctionsState.filter,
          term: payload.term,
        },
        meta: {
          ...auctionsState.meta,
          page: 1,
        },
      };
    case auctionsActions.changedCategoryFilter.Event:
      return {
        ...auctionsState,
        filter: {
          ...auctionsState.filter,
          categories: payload.categories,
        },
        meta: {
          ...auctionsState.meta,
          page: 1,
        },
      };
    case auctionsActions.fetchAuctions.Request ||
      auctionsActions.fetchMyAuctions.Request ||
      auctionsActions.fetchMyBids.Request:
      return { ...auctionsState, isLoading: true };
    case auctionsActions.fetchAuctions.Success ||
      auctionsActions.fetchMyAuctions.Success ||
      auctionsActions.fetchMyBids.Success:
      return {
        ...auctionsState,
        resources: [...(payload?.resources || [])],
        meta: {
          ...auctionsState.meta,
          total: payload?.meta?.total || 0,
        },
        isLoading: false,
      };
    case auctionsActions.fetchAuctions.Failure:
      return { ...auctionsInitialState };

    //
    case auctionsActions.fetchAuction.Request:
      return { ...auctionsInitialState, isLoading: true };

    case auctionsActions.fetchAuction.Success:
      return {
        ...auctionsState,
        resource: { ...payload },
        isLoading: false,
      };
    case auctionsActions.fetchAuction.Failure:
      return { ...auctionsInitialState };

    // execute order
    case auctionsActions.executeOrder.Success:
      return {
        ...auctionsState,
        resource: { ...payload.resource },
        isLoading: false,
      };

    case auctionsActions.executeOrder.Failure:
      return { ...auctionsInitialState };

    // receive order
    case auctionsActions.receiveOrder.Success:
      return {
        ...auctionsState,
        resource: { ...payload.resource },
        isLoading: false,
      };

    case auctionsActions.receiveOrder.Failure:
      return { ...auctionsInitialState };

    // clear
    case auctionsActions.clearAuction.Success:
      return { ...auctionsInitialState };

    case auctionsActions.changeDisplay.Event:
      return {
        ...auctionsState,
        displayGrid: payload.displayGrid,
      };

    case auctionsActions.changePagination.Event: {
      const meta = auctionsState.meta;

      const newState = {
        ...auctionsState,
        meta: {
          ...meta,
          ...payload,
        },
      };
      if (auctionsState.meta.page === 1) newState.resources = [];
      return newState;
    }
    default:
      return { ...auctionsState };
  }
};
