import React, { Ref } from "react";
import CreateAuctionForm from "../../components/form/auction.create";
import { StripeCheckoutInterface } from "../../components/stripe/stripe.checkout";
import { useAuth } from "react-oidc-context";
import { CreateAuctionDto } from "../../gen/rq";
import { useFormik } from "formik";

const AuctionCreate: React.FC = () => {
  const auth = useAuth();
  //const stripePromise = loadStripe('pk_test_51IaLvvKDaEQwlk51ANXb4sVC8M2yTDW2Nh1WvztqOsuhsNLyyPylfZrDV8HarEjYz0wvkbGSDvnsYp331KcA8iPJ00Vc4rPDYo');

  const stripeRef: Ref<StripeCheckoutInterface> = React.useRef<StripeCheckoutInterface>(null);

  return auth.isAuthenticated ? (
    <section className="container">
      <CreateAuctionForm />
    </section>
  ) : (
    <p> You need to sign in before creating auction. </p>
  );
};

export default AuctionCreate;
