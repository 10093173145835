import { fork, takeEvery } from "typed-redux-saga";
import * as auctionsActions from "../domain/auctions/actions";
// auctions
import * as auctionSagas from "../domain/auctions/sagas";
import * as bidsActions from "../domain/bids/actions";
// bids
import { fetchBids } from "../domain/bids/sagas";
import * as ordersActions from "../domain/orders/actions";
// orders
import { createOrder, fetchOrder, fetchOrders, updateOrder } from "../domain/orders/sagas";
import * as siteActions from "../domain/site/actions";
import * as siteSagas from "../domain/site/sagas";
import * as userActions from "../domain/user/actions";
// users
import * as userSagas from "../domain/user/sagas";

interface WatchComponents {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [s: string]: any;
}

const watchComponents: WatchComponents = {
  [siteActions.FETCH_SITE.Request]: siteSagas.fetchSite,
  [auctionsActions.fetchAuctions.Request]: auctionSagas.fetchAuctions,
  [auctionsActions.fetchMyAuctions.Request]: auctionSagas.fetchMyAuctions,
  [auctionsActions.fetchMyBids.Request]: auctionSagas.fetchMyBids,
  [auctionsActions.searchAction.Request]: auctionSagas.debounceSearchAction,
  [auctionsActions.fetchAuction.Request]: auctionSagas.fetchAuction,
  [auctionsActions.clearAuction.Request]: auctionSagas.clearAuction,
  [auctionsActions.createAuction.Request]: auctionSagas.createNewAuction,
  [auctionsActions.updateAuction.Request]: auctionSagas.updateAuction,
  [auctionsActions.deleteAuction.Request]: auctionSagas.deleteAuction,
  [auctionsActions.setAuction.Request]: auctionSagas.setAuction,
  [auctionsActions.uploadCover.Request]: auctionSagas.uploadAuctionCover,
  [auctionsActions.executeOrder.Request]: auctionSagas.executeOrder,
  [auctionsActions.receiveOrder.Request]: auctionSagas.receiveOrder,

  [bidsActions.FETCH_BIDS.Request]: fetchBids,

  [userActions.FETCH_PROFILE.Request]: userSagas.fetchProfile,
  [userActions.CREATE_USER.Request]: userSagas.signUp,
  [userActions.LOGIN.Request]: userSagas.makeLogin,
  [userActions.LOGOUT.Request]: userSagas.makeLogout,
  [userActions.VERIFY_EMAIL.Request]: userSagas.sendVerifyEmail,
  [userActions.FORGOT_PASSWORD.Request]: userSagas.sendForgotPassword,
  [userActions.RESET_PASSWORD.Request]: userSagas.sendResetPassword,

  [ordersActions.FETCH_ORDERS.Request]: fetchOrders,
  [ordersActions.FETCH_ORDER.Request]: fetchOrder,
  [ordersActions.CREATE_ORDER.Request]: createOrder,
  [ordersActions.UPDATE_ORDER.Request]: updateOrder,
};

export function* components() {
  for (const request in watchComponents) {
    yield takeEvery(request, watchComponents[request]);
  }
}

export default function* () {
  yield fork(components);
}
