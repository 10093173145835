/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Auctioneer API
 * Auctioneer API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { Create201, CreateSiteDto, SearchSiteDto, SearchSitesDto, SiteDto, UpdateSiteDto } from "./model";
import { customAxiosInstance } from "../custom-axios";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const settings = (signal?: AbortSignal) => {
  return customAxiosInstance<SiteDto>({ url: `/v1/site/settings`, method: "GET", signal });
};

export const getSettingsQueryKey = () => {
  return [`/v1/site/settings`] as const;
};

export const getSettingsQueryOptions = <TData = Awaited<ReturnType<typeof settings>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settings>>> = ({ signal }) => settings(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof settings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SettingsQueryResult = NonNullable<Awaited<ReturnType<typeof settings>>>;
export type SettingsQueryError = unknown;

export const useSettings = <TData = Awaited<ReturnType<typeof settings>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof settings>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const update = (id: string, updateSiteDto: UpdateSiteDto) => {
  return customAxiosInstance<void>({
    url: `/v1/site/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateSiteDto,
  });
};

export const getUpdateMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { id: string; data: UpdateSiteDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof update>>, TError, { id: string; data: UpdateSiteDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof update>>, { id: string; data: UpdateSiteDto }> = (
    props,
  ) => {
    const { id, data } = props ?? {};

    return update(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMutationResult = NonNullable<Awaited<ReturnType<typeof update>>>;
export type UpdateMutationBody = UpdateSiteDto;
export type UpdateMutationError = unknown;

export const useUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof update>>,
    TError,
    { id: string; data: UpdateSiteDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof update>>, TError, { id: string; data: UpdateSiteDto }, TContext> => {
  const mutationOptions = getUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const _delete = (id: string) => {
  return customAxiosInstance<void>({ url: `/v1/site/${id}`, method: "DELETE" });
};

export const getDeleteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof _delete>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return _delete(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type _DeleteMutationResult = NonNullable<Awaited<ReturnType<typeof _delete>>>;

export type _DeleteMutationError = unknown;

export const useDelete = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof _delete>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
export const create = (createSiteDto: CreateSiteDto) => {
  return customAxiosInstance<Create201>({
    url: `/v1/site`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createSiteDto,
  });
};

export const getCreateMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof create>>, TError, { data: CreateSiteDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof create>>, TError, { data: CreateSiteDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof create>>, { data: CreateSiteDto }> = (props) => {
    const { data } = props ?? {};

    return create(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMutationResult = NonNullable<Awaited<ReturnType<typeof create>>>;
export type CreateMutationBody = CreateSiteDto;
export type CreateMutationError = unknown;

export const useCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof create>>, TError, { data: CreateSiteDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof create>>, TError, { data: CreateSiteDto }, TContext> => {
  const mutationOptions = getCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const siteSearch = (searchSiteDto: SearchSiteDto) => {
  return customAxiosInstance<SearchSitesDto>({
    url: `/v1/site/search`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchSiteDto,
  });
};

export const getSiteSearchMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof siteSearch>>, TError, { data: SearchSiteDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof siteSearch>>, TError, { data: SearchSiteDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof siteSearch>>, { data: SearchSiteDto }> = (props) => {
    const { data } = props ?? {};

    return siteSearch(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SiteSearchMutationResult = NonNullable<Awaited<ReturnType<typeof siteSearch>>>;
export type SiteSearchMutationBody = SearchSiteDto;
export type SiteSearchMutationError = unknown;

export const useSiteSearch = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof siteSearch>>, TError, { data: SearchSiteDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof siteSearch>>, TError, { data: SearchSiteDto }, TContext> => {
  const mutationOptions = getSiteSearchMutationOptions(options);

  return useMutation(mutationOptions);
};
