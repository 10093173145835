// reducers
import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject } from "redux";
// redux-form
import { reducer as ReduxFormReducers } from "redux-form";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { initialStripeState, stripeReducer, StripeStateInterface } from "../components/stripe/stripe.reducer";
import { auctionsInitialState, auctionsReducers, AuctionsStateInterface } from "./auctions/reducers";
import { bidsInitialState, bidsReducers, BidsStateInterface } from "./bids/reducers";
import { ordersInitialState, ordersReducers, OrderState } from "./orders/reducers";
import { siteInitialState, siteReducer, SiteStateInterface } from "./site/reducers";
import { userInitialState, userReducer, UserStateInterface } from "./user/reducers";
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

export interface StateInterface {
  site: SiteStateInterface;
  user: UserStateInterface;
  auctions: AuctionsStateInterface;
  bids: BidsStateInterface;
  orders: OrderState;
  stripe: StripeStateInterface;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
}

const stateInitialValue: StateInterface = {
  site: siteInitialState,
  user: userInitialState,
  auctions: auctionsInitialState,
  bids: bidsInitialState,
  orders: ordersInitialState,
  stripe: initialStripeState,
  form: null,
};

const reducers: ReducersMapObject = {
  site: siteReducer,
  auctions: auctionsReducers,
  user: userReducer,
  bids: bidsReducers,
  orders: ordersReducers,
  stripe: stripeReducer,
  form: ReduxFormReducers,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  /*
  const loggerMiddleware = createLogger({
    collapsed: (getState, action) => action.type.indexOf("@@redux-form") > -1,
  });*/
  const store = createStore(
    combineReducers(reducers),
    stateInitialValue,
    composeEnhancers(applyMiddleware(sagaMiddleware)) // , thunkMiddleware
  );
  return { ...store, runSaga: sagaMiddleware.run };
};

export interface Meta {
  page: number;
  pageSize: number;
  total: number;
}
