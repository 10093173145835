import React from "react";
import { useHitsPerPage, UseHitsPerPageProps, usePagination, UsePaginationProps } from "react-instantsearch";

const HitsPerPage = (props: UseHitsPerPageProps) => {
  const { items, refine } = useHitsPerPage(props);
  const { value } = items.find(({ isRefined }) => isRefined) || {};
  return (
    <div>
      <select
        name="page-size"
        aria-label="Page Size"
        required
        style={{ marginTop: 0, width: "auto" }}
        onChange={(e) => {
          refine(+e.currentTarget.value);
        }}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const Pagination = (props: UsePaginationProps) => {
  const { nbPages, refine, currentRefinement } = usePagination(props);
  return (
    <div role="group" style={{ float: "right", width: "auto" }}>
      {/* pages.map((page) => ( */}

      <button
        key={`page-${currentRefinement - 1}`}
        disabled={currentRefinement < 1}
        onClick={(e) => {
          e.preventDefault();
          refine(Math.max(currentRefinement - 1, 0));
        }}
      >
        <i className="fa fa-chevron-left" />
      </button>
      <span role="button" className="secondary outline">
        {currentRefinement + 1 } of {nbPages}
      </span>
      <button
        key={`page-${currentRefinement + 1}`}
        disabled={currentRefinement >= nbPages}
        onClick={(e) => {
          e.preventDefault();
          refine(Math.min(currentRefinement + 1, nbPages));
        }}
      >
        <i className="fa fa-chevron-right" />
      </button>
      {/*))} */}
    </div>
  );
};

export const AlgoliaBottomBar: React.FC = () => {
  return (
    <section className="grid" style={{ marginTop: 20 }}>
      <HitsPerPage
        items={[
          {
            label: "24",
            value: 24,
            default: true,
          },
          {
            label: "48",
            value: 48,
          },
          {
            label: "96",
            value: 96,
          },
        ]}
      />
      <Pagination />
    </section>
  );
};

export default AlgoliaBottomBar;
