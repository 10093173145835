import { HierarchicalMenuProps, useCurrentRefinements } from "react-instantsearch";
import React from "react";
import styles from "./aloglia.current.refinement.module.css";
import { useTranslation } from "react-i18next";
export const AuxCurrentRefinements = () => {
  const { t } = useTranslation();
  const { items } = useCurrentRefinements();

  const refinementLabel = (label: string, attribute: string) => {
    if (attribute.startsWith("hierarchicalLocations")) {
      label = label.split(" > ").reverse().join(", ");
    }
    if (attribute.startsWith("hierarchicalCategories")) {
      label = label.split(" > ").pop() || "-";
    }

    return label.substring(0, 1).toUpperCase() + label.substring(1);
  };
  return (
    <div className={`${styles.refinements}`}>
      {items.reverse().map((item, index) => {
        const { refine } = item;
        return (
          <>
            <span key={`filter-${item.attribute}`} className={styles.filter}>
              {(() => {
                return t(`filter.${item.label}`);
              })()}
            </span>
            {item.refinements.map((refinement) => (
              <div key={refinement.value} onClick={() => refine(refinement)} className={styles.badge}>
                {refinementLabel(refinement.label, refinement.attribute)}{" "}
                <span className={`${styles.remove} fa fa-remove`}></span>
              </div>
            ))}
            {index < items.length -1 ? <span className={styles.separator}>, </span> : null}
          </>
        );
      })}
    </div>
  );
};
