import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { AuctionDtoDeliveryItem, CreateAuctionDto, useCreateAuction } from "../../gen/rq";
import * as Yup from "yup";
import { AuxUploadForm } from "./upload.field";

const CreateAuctionValidationSchema = Yup.object().shape({
  title: Yup.string().min(3, "Little bit longer").max(64, "Too long!").required("Forgot to add item title..."),
  description: Yup.string().min(10, "Too Short!").max(1024, "Too Long!").optional(),
  askPrice: Yup.number().required(),
  location: Yup.string()
    .matches(/^(\w+|\s*)*,(w+|\s*)*$/)
    .required(),
  buyoutPrice: Yup.number().optional(),
  currency: Yup.string().required(),
  startDate: Yup.string().optional(),
  endDate: Yup.string().optional(),
});
interface CreateSingleAuctionProps {}
const CreateAuctionForm: React.FC<CreateSingleAuctionProps> = () => {
  const { mutate } = useCreateAuction();
  const [delivery, setDelivery] = useState<AuctionDtoDeliveryItem[]>([]);
  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        askPrice: 1,
        buyoutPrice: 0,
        endTime: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
        currency: "SEK",
        location: "Sweden, Stockholm",
        delivery: [],
      }}
      validationSchema={CreateAuctionValidationSchema}
      onSubmit={(values) => {
        const data = { ...values, medias: [], delivery: delivery } as CreateAuctionDto;
        mutate({ data }, { onSuccess: () => {} });
      }}
    >
      {({ errors, touched, values }) => (
        <Form>
          <article>
            <header>Create action</header>
            <section className="grid">
              <div>
                <label>
                  Item
                  <Field
                    name="title"
                    placeholder="What item are you selling?"
                    aria-invalid={errors.title && touched.title}
                  />
                  {errors.title && touched.title ? <small>{errors.title}</small> : null}
                </label>
                <label>
                  Asking price
                  <fieldset role="group">
                    <Field
                      name="askPrice"
                      type="text"
                      style={{ textAlign: "right" }}
                      placeholder="asking price..."
                      autoComplete="off"
                      aria-invalid={errors.title && touched.title}
                    />

                    <Field disabled name="currency" />
                    {/*<select>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                  </select>*/}
                  </fieldset>
                  {errors.askPrice && touched.askPrice ? <small>{errors.askPrice}</small> : null}
                </label>
                <label>
                  Buy out
                  <fieldset role="group">
                    <Field
                      name="buyoutPrice"
                      type="text"
                      placeholder="buy now..."
                      autoComplete="off"
                      style={{ textAlign: "right" }}
                      aria-invalid={errors.title && touched.title}
                      onChange={(value: string): number => parseFloat(value)}
                    />

                    <Field disabled name="currency" />
                    {/*<select>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                  </select>*/}
                  </fieldset>
                </label>
              </div>
              <div>
                {/*delivery.includes(AuctionDtoDeliveryItem.pickup) ? ( */}
                <label>
                  Where
                  <Field name="location" placeholder="city, country" />
                </label>
                {/* ) : null} */}
                <label>Delivery</label>
                <details className="dropdown">
                  <summary>{`${delivery.length > 0 ? "" + delivery.join(", ") : ""}`}</summary>
                  <ul>
                    {Object.values(AuctionDtoDeliveryItem).map((label) => (
                      <>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              name={label}
                              onChange={(e) => {
                                if (e.currentTarget.checked && !delivery.includes(label)) {
                                  setDelivery([...delivery, AuctionDtoDeliveryItem[label]]);
                                } else {
                                  setDelivery(delivery.filter((item) => item !== label));
                                }
                              }}
                            />
                            {label}
                          </label>
                        </li>
                      </>
                    ))}
                  </ul>
                </details>
                <label>
                  End date
                  <Field
                    name="endTime"
                    type="datetime-local"
                    aria-label="Datetime local"
                    placeholder="end date"
                    aria-invalid={errors.endTime && touched.endTime}
                  />
                </label>
              </div>
            </section>
            <AuxUploadForm />
            <section>
              <details open>
                <summary>Description</summary>
                <div>
                  <Field
                    type="text"
                    name="description"
                    placeholder="Give an description of the item you are selling."
                  />
                </div>
              </details>
              <hr />
              <details>
                <summary>Additional information</summary>
                <div>
                  <label>Produkt url</label>
                  <Field type="url" name="url" placeholder="Url" aria-label="Url" />
                  <Field
                    type="text"
                    name="description"
                    placeholder="Give an description of the item you are selling."
                  />
                </div>
              </details>
            </section>
            <footer>
              <div role="group">
                <button type="reset">Reset</button>
                <button type="submit">Create</button>
              </div>
            </footer>
          </article>
        </Form>
      )}
    </Formik>
  );
};

export default CreateAuctionForm;
{
  /*
        <Grid item>
          
        </Grid>
        
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        lg={4}
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item>
         
        </Grid>
        <Grid item>

          {startAdornment={
              <InputAdornment position="start">
                <AccessAlarm />
              </InputAdornment>
            }}
        </Grid>
        <Grid item>
          <Field
            name="reservePrice"
            type="text"
            placeholder="Start price"
            variant="outlined"
            autoComplete="off"
            size="small"
            fullWidth
            parse={(value: string): number => parseFloat(value)}
          />
          {startAdornment={
              <InputAdornment position="start">
                <AttachMoney />
              </InputAdornment>
            }}
        </Grid>
        <Grid item>
          <Typography variant="h5">End date</Typography>
        </Grid>
        <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker onChange={(value, context) => handleDateChange(value as Date)}/>
            </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} lg={6} direction="row">
        <Grid item xs={12}>
          <Typography variant="h5">Settings</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              label={`Anti Snipe`}
              control={<Checkbox name="antiSnipe" />}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} lg={6} direction="row">
        <Grid item xs={12}>
          <Typography variant="h5">Upload</Typography>
        </Grid>
        <Grid item xs={12}>
          {
          <DropzoneArea
            onChange={(files: File[]): void => console.log("Files:", files)}
          />
          <DropzoneDialog acceptedFiles={["image/*", "video/*"]} />
          }
        </Grid>
      </Grid>
    </> */
}
