/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from "typed-redux-saga";
import * as usersActions from "../../domain/user/actions";
import {
  AuthActionType,
  ForgotPasswordActionType,
  ResetPasswordActionType,
  SignUpActionType,
  VerifyEmailActionType,
} from "../../interfaces/actionTypes";
import legacyApi from "../../libs/api";
import { showAxiosErrors, toast } from "../../libs/helpers";
import { clearStorage, setStorageItem } from "../../libs/storage";
import { AuthInterface } from "./../../interfaces/user";

export function* fetchProfile() {
  try {
    const { data } = yield call(legacyApi.fetchProfile);

    yield put({
      type: usersActions.FETCH_PROFILE.Success,
      payload: data,
    });
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.FETCH_PROFILE.Failure,
      payload: errors,
    });
  }
}

function setUserToLocalStorage(data: AuthInterface) {
  const {
    token,
    user: { id, email, firstName },
  } = data;
  setStorageItem("id", id);
  setStorageItem("token", token);
  setStorageItem("email", email);
  setStorageItem("firstName", firstName);
}

export function* signUp({ payload: { newUser }, navigate }: SignUpActionType) {
  try {
    const { data } = yield call(legacyApi.createUser, { newUser });

    yield put({
      type: usersActions.CREATE_USER.Success,
      payload: data,
    });

    toast("User successfully registered!", "success");

    setUserToLocalStorage(data);
    navigate("/auth/signup/success");
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.CREATE_USER.Failure,
      payload: errors,
    });
  }
}

export function* makeLogin({ payload, navigate }: AuthActionType) {
  try {
    const { data } = yield call(legacyApi.login, payload);
    yield put({
      type: usersActions.LOGIN.Success,
      payload: data,
    });

    setUserToLocalStorage(data);
    navigate("/auctions");
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.LOGIN.Failure,
      payload: errors,
    });
  }
}

export function* makeLogout({ navigate }: AuthActionType) {
  clearStorage();
  yield put({ type: usersActions.LOGOUT.Success });
  navigate("/");
}

export function* sendVerifyEmail({ payload: { token }, navigate }: VerifyEmailActionType) {
  try {
    const { data } = yield call(legacyApi.verifyEmail, { token });
    yield put({
      type: usersActions.VERIFY_EMAIL.Success,
      payload: data,
    });

    setUserToLocalStorage(data.resource);
    navigate("/auctions");
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.VERIFY_EMAIL.Failure,
    });
  }
}

export function* sendForgotPassword({ payload: { email }, navigate }: ForgotPasswordActionType) {
  try {
    const { data } = yield call(legacyApi.forgotPassword, { email });
    yield put({
      type: usersActions.FORGOT_PASSWORD.Success,
      payload: data,
    });
    navigate("/auth/login");
    toast("Letter sent! Check your email.", "success");
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.FORGOT_PASSWORD.Failure,
    });
  }
}

export function* sendResetPassword({ payload, navigate }: ResetPasswordActionType) {
  try {
    const { data } = yield call(legacyApi.resetPassword, payload);
    yield put({
      type: usersActions.RESET_PASSWORD.Success,
      payload: data,
    });
    navigate("/auth/login");
    toast("Password changes. Try to login", "success");
  } catch (errors: any) {
    showAxiosErrors(errors.response);
    yield put({
      type: usersActions.RESET_PASSWORD.Failure,
    });
  }
}
