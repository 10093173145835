import { devtools, persist } from "zustand/middleware";
import type {} from "@redux-devtools/extension";
import { create } from "zustand";
import { SiteDto } from "../gen/rq";
interface AuxProps {
  site?: SiteDto;
  mode?: "row" | "card";
}
interface AuxState extends AuxProps {
  setSite: (siteId?: SiteDto) => void;
  setMode: (mode: "row" | "card") => void;
}

export const useAuxStore = create<AuxState>()(
  devtools(
    persist(
      (set) => ({
        setSite: (site?: SiteDto) => set((state) => ({ site })),
        setMode: (mode: "row" | "card" = "row") => set((state) => ({ mode })),
      }),
      {
        name: "aux-storage",
      },
    ),
  ),
);
