/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Auctioneer API
 * Auctioneer API
 * OpenAPI spec version: 1.0
 */

export type AuctionDtoDeliveryItem = (typeof AuctionDtoDeliveryItem)[keyof typeof AuctionDtoDeliveryItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuctionDtoDeliveryItem = {
  pickup: "pickup",
  shipping: "shipping",
} as const;
