import { Reducer } from "redux";
import { StripeCountry, StripeCurrency } from "./stripe.data";

export interface StripeFormInterface {
  country: StripeCountry;
  firstname: string;
  lastname: string;
  email: string;
  line1: string;
  line2?: string;
  postal_code: string;
  city: string;
  [key: string]: string | number | StripeCountry | null | undefined;
}
export interface PaymentIntentInterface {
  clientSecret: string;
}
export interface StripeStateInterface {
  formValues: StripeFormInterface;
  paymentIntent: PaymentIntentInterface;
}

export const initialStripeState: StripeStateInterface = {
  formValues: {
    date: "",
    firstname: "Niklas",
    lastname: "Blomberg",
    email: "blomberg.niklas@gmail.com",
    line1: "Gata 1",
    line2: "",
    postal_code: "1234",
    city: "Sthlm",
    currency: {
      symbol: "Skr",
      name: "Swedish Krona",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "SEK",
      name_plural: "Swedish kronor",
    } as StripeCurrency,
    country: { name: "Sweden", code: "SE" } as StripeCountry,
    amount: 15,
  } as StripeFormInterface,
  paymentIntent: {
    clientSecret: "",
  },
};

export interface StripeAction {
  type: string;
  key?: string;
  value?: string;
}

export const stripeReducer: Reducer = (
  state: StripeStateInterface = initialStripeState,
  action: StripeAction
): StripeStateInterface => {
  const { type, key, value } = action;
  switch (type) {
    case "editFormValue":
      state.formValues[key!.toLowerCase()] = value;
      return { ...state };

    case "emptyFormValue":
      return {
        ...state,
        formValues: initialStripeState.formValues,
      };
    default:
  }
  return state;
};
