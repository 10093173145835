/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { LoginInterface } from "../components/form/loginForm";
// interfaces
import { OrderFormValues } from "../components/form/orderForm";
import { UserCreateInterface } from "../components/form/signupForm";
import { AuctionApi, Configuration, OrderApi, PaymentApi, UserApi } from "../gen/axios-api";
import { getStorageItem } from "../libs/storage";
import { AuctionsApi, CategoryApi, MediaApi, SiteApi } from "./../gen/axios-api/api";
declare global {
  interface Window {
    auctioneerConfig?: { siteKey: string };
  }
}
axios.defaults.baseURL = `${process.env.REACT_APP_AUCTIONEER_API_URL}`;
const configuration: Configuration = new Configuration({
  accessToken: () => getStorageItem("token", "None"),
  basePath: axios.defaults.baseURL,
  apiKey: (key: string) => {
    switch (key) {
      case "x-site-key":
        return window?.auctioneerConfig?.siteKey || process.env.REACT_APP_SITE_KEY || "None";
      default:
    }
    return "";
  },
});

export const auctionsApi = new AuctionsApi(configuration);
export const auctionApi = new AuctionApi(configuration);
export const ordersApi = new OrderApi(configuration);
export const usersApi = new UserApi(configuration);
export const categoryApi = new CategoryApi(configuration);
export const siteApi = new SiteApi(configuration);
export const mediaApi = new MediaApi(configuration);
export const paymentApi = new PaymentApi(configuration);

const prefix = "";
// auctions
export default {
  // executeOrder
  executeOrder: ({ auctionId }: { auctionId: number }) =>
    axios.post(`${prefix}/auctions/${auctionId}/executeOrder`, {}),
  // receiveOrder
  receiveOrder: ({ auctionId }: { auctionId: number }) =>
    axios.post(`${prefix}/auctions/${auctionId}/receiveOrder`, {}),

  // orders
  fetchOrders: ({ filters }: { filters: string | undefined }) =>
    axios.get(`${prefix}/orders${filters ? "?filters=" + filters : ""}`),
  fetchOrder: ({ orderId }: { orderId: number }) => axios.get(`${prefix}/orders/${orderId}`),
  createOrder: ({ auctionId, order }: { auctionId: number; order: OrderFormValues }) =>
    axios.post(`${prefix}/auctions/${auctionId}/order`, order),
  updateOrder: ({ auctionId, order }: { auctionId: number; order: OrderFormValues }) =>
    axios.put(`${prefix}/auctions/${auctionId}/order`, order),

  // bids
  fetchBids: ({ auctionId }: { auctionId: number }) => axios.get(`${prefix}/auctions/${auctionId}/bids`),

  // user
  fetchProfile: () => axios.get(`${prefix}/users/profile`),
  createUser: ({ newUser }: { newUser: UserCreateInterface }) => axios.post(`${prefix}/auth/signup`, newUser),

  // auth
  login: ({ loginData }: { loginData: LoginInterface }) => axios.post(`${prefix}/auth/login`, loginData),
  verifyEmail: ({ token }: { token: string }) => axios.post(`${prefix}/auth/verify/email`, { token }),
  forgotPassword: ({ email }: { email: string }) => axios.post(`${prefix}/auth/forgot_password`, { email }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  resetPassword: (sendData: object) => axios.post(`${prefix}/auth/reset_password`, sendData),
};
