/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Auctioneer API
 * Auctioneer API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { AuctionDto, Bid, BidDto, CreateAuctionDto, CreateBid, UpdateAuctionDto } from "./model";
import { customAxiosInstance } from "../custom-axios";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const auction = (auctionId: number, signal?: AbortSignal) => {
  return customAxiosInstance<AuctionDto>({ url: `/v1/auction/${auctionId}`, method: "GET", signal });
};

export const getAuctionQueryKey = (auctionId: number) => {
  return [`/v1/auction/${auctionId}`] as const;
};

export const getAuctionQueryOptions = <TData = Awaited<ReturnType<typeof auction>>, TError = unknown>(
  auctionId: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auction>>, TError, TData>> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuctionQueryKey(auctionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auction>>> = ({ signal }) => auction(auctionId, signal);

  return { queryKey, queryFn, enabled: !!auctionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auction>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuctionQueryResult = NonNullable<Awaited<ReturnType<typeof auction>>>;
export type AuctionQueryError = unknown;

export const useAuction = <TData = Awaited<ReturnType<typeof auction>>, TError = unknown>(
  auctionId: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auction>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuctionQueryOptions(auctionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const removeAuction = (auctionId: number) => {
  return customAxiosInstance<void>({ url: `/v1/auction/${auctionId}`, method: "DELETE" });
};

export const getRemoveAuctionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeAuction>>, TError, { auctionId: number }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof removeAuction>>, TError, { auctionId: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeAuction>>, { auctionId: number }> = (props) => {
    const { auctionId } = props ?? {};

    return removeAuction(auctionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof removeAuction>>>;

export type RemoveAuctionMutationError = unknown;

export const useRemoveAuction = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeAuction>>, TError, { auctionId: number }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof removeAuction>>, TError, { auctionId: number }, TContext> => {
  const mutationOptions = getRemoveAuctionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateAuction = (auctionId: number, updateAuctionDto: UpdateAuctionDto) => {
  return customAxiosInstance<AuctionDto>({
    url: `/v1/auction/${auctionId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateAuctionDto,
  });
};

export const getUpdateAuctionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAuction>>,
    TError,
    { auctionId: number; data: UpdateAuctionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAuction>>,
  TError,
  { auctionId: number; data: UpdateAuctionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAuction>>,
    { auctionId: number; data: UpdateAuctionDto }
  > = (props) => {
    const { auctionId, data } = props ?? {};

    return updateAuction(auctionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAuction>>>;
export type UpdateAuctionMutationBody = UpdateAuctionDto;
export type UpdateAuctionMutationError = unknown;

export const useUpdateAuction = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAuction>>,
    TError,
    { auctionId: number; data: UpdateAuctionDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAuction>>,
  TError,
  { auctionId: number; data: UpdateAuctionDto },
  TContext
> => {
  const mutationOptions = getUpdateAuctionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bids = (auctionId: number, signal?: AbortSignal) => {
  return customAxiosInstance<BidDto[]>({ url: `/v1/auction/${auctionId}/bids`, method: "GET", signal });
};

export const getBidsQueryKey = (auctionId: number) => {
  return [`/v1/auction/${auctionId}/bids`] as const;
};

export const getBidsQueryOptions = <TData = Awaited<ReturnType<typeof bids>>, TError = unknown>(
  auctionId: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof bids>>, TError, TData>> },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBidsQueryKey(auctionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof bids>>> = ({ signal }) => bids(auctionId, signal);

  return { queryKey, queryFn, enabled: !!auctionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof bids>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BidsQueryResult = NonNullable<Awaited<ReturnType<typeof bids>>>;
export type BidsQueryError = unknown;

export const useBids = <TData = Awaited<ReturnType<typeof bids>>, TError = unknown>(
  auctionId: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof bids>>, TError, TData>> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBidsQueryOptions(auctionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createAuction = (createAuctionDto: CreateAuctionDto) => {
  return customAxiosInstance<AuctionDto>({
    url: `/v1/auction/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAuctionDto,
  });
};

export const getCreateAuctionMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAuction>>,
    TError,
    { data: CreateAuctionDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof createAuction>>, TError, { data: CreateAuctionDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAuction>>, { data: CreateAuctionDto }> = (
    props,
  ) => {
    const { data } = props ?? {};

    return createAuction(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof createAuction>>>;
export type CreateAuctionMutationBody = CreateAuctionDto;
export type CreateAuctionMutationError = void;

export const useCreateAuction = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAuction>>,
    TError,
    { data: CreateAuctionDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof createAuction>>, TError, { data: CreateAuctionDto }, TContext> => {
  const mutationOptions = getCreateAuctionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const activate = (auctionId: number) => {
  return customAxiosInstance<AuctionDto>({ url: `/v1/auction/${auctionId}/activate`, method: "PUT" });
};

export const getActivateMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activate>>, TError, { auctionId: number }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof activate>>, TError, { auctionId: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof activate>>, { auctionId: number }> = (props) => {
    const { auctionId } = props ?? {};

    return activate(auctionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivateMutationResult = NonNullable<Awaited<ReturnType<typeof activate>>>;

export type ActivateMutationError = unknown;

export const useActivate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof activate>>, TError, { auctionId: number }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof activate>>, TError, { auctionId: number }, TContext> => {
  const mutationOptions = getActivateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const placeBid = (auctionId: number, createBid: CreateBid) => {
  return customAxiosInstance<BidDto | Bid>({
    url: `/v1/auction/${auctionId}/bid`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createBid,
  });
};

export const getPlaceBidMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof placeBid>>,
    TError,
    { auctionId: number; data: CreateBid },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof placeBid>>,
  TError,
  { auctionId: number; data: CreateBid },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof placeBid>>, { auctionId: number; data: CreateBid }> = (
    props,
  ) => {
    const { auctionId, data } = props ?? {};

    return placeBid(auctionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PlaceBidMutationResult = NonNullable<Awaited<ReturnType<typeof placeBid>>>;
export type PlaceBidMutationBody = CreateBid;
export type PlaceBidMutationError = unknown;

export const usePlaceBid = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof placeBid>>,
    TError,
    { auctionId: number; data: CreateBid },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof placeBid>>,
  TError,
  { auctionId: number; data: CreateBid },
  TContext
> => {
  const mutationOptions = getPlaceBidMutationOptions(options);

  return useMutation(mutationOptions);
};
